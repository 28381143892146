.container-plataforma {
    display: flex;
}

.container-home {
    margin-top: 65px;
    flex: 1 1 100%;
    -webkit-transition: width .3s ease-in-out;
    -moz-transition: width .3s ease-in-out;
    -o-transition: width .3s ease-in-out;
    transition: width .3s ease-in-out;
    width: 100%;
}

.container-home.barra-activa {
    flex: none;
    width: calc(100vw - 360px);
}

@media (max-width: 714px) {
    .container-home.barra-activa {
        width: 100%;
    }

    .container-home {
        margin-top: 123px;
    }
}
