.listado-formaEntrega .formaEntrega{
    background-color: white;
    box-shadow: 0 1px 3px 0 rgba(0,0,0,.15);
    border-radius: 4px;
    padding: 20px;
    font-family: Montserrat;
    font-weight: 100;
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    cursor: pointer;
    border-left: 6px solid transparent;
}

.listado-formaEntrega .formaEntrega{
    background-color: white;
    box-shadow: 0 1px 3px 0 rgba(0,0,0,.15);
    border-radius: 4px;
    padding: 20px;
    font-family: Montserrat;
    font-weight: 100;
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    cursor: pointer;
    border-left: 6px solid transparent;
}

.listado-formaEntrega .formaEntrega:hover {
    background-color: #f5f5f5;
}

.listado-formaEntrega .formaEntrega.active{
    border-left: 6px solid  var(--btn-color-principal);
}

.listado-formaEntrega .formaEntrega div{
    display: flex;
    align-items: center;
}

.listado-formaEntrega .formaEntrega .img svg{
    width: 100%;
    height: 100%;
}

.listado-formaEntrega .formaEntrega .img path{
    fill: var(--btn-color-principal);
}

.listado-formaEntrega .formaEntrega .img{
    width: 45px;
    height: 45px;
    padding: 5px;
    margin-right: 5px;
}

.listado-formaEntrega .formaEntrega div:first-child p{
    margin: 0px;
}

.listado-formaEntrega .mensaje{
    display: none;
    color: #9e1f22;
    margin-bottom: 10px;
}

.listado-formaEntrega .mensaje.visible{
    display: block;
}