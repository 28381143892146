.barra-categorias .categorias-container {
    background-color: var(--bg-cat-principal);
    height: 82px;
    padding-right: 5px;
    padding-left: 5px;
}

.buscador-section .barra-categorias .categorias-container {
    height: 73px;
}

.barra-categorias .categorias-container li {
    max-width: 150px;
    background-color: transparent;
    padding: 6px 10px;
    border: 1px solid;
    border-color: var(--border-bot-cat);
    color: var(--tx-bot-cat);
    min-width: 134px;
    text-align: center;
    /*box-shadow: 1px 1px 4px #00000033;*/
    border-radius: 30px;
    margin-left: 5px;
    margin-right: 5px;
    text-transform: uppercase;
    cursor: pointer;
    -webkit-transition: ease .2s;
    -moz-transition: ease .2s;
    -ms-transition: ease .2s;
    -o-transition: ease .2s;
    transition: ease .2s;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 11px;
}

@media (max-width: 714px ) {
    .barra-categorias .categorias-container li {
        font-size: 10px;
    }
}

.barra-categorias .categorias-container li:hover {
    transform: scale(1.04);
}

.barra-categorias .categorias-container a {
    text-decoration: none !important;
    outline: none;
    height: 100%;
    max-height: 50px;
    align-self: center;
}

.barra-categorias .categorias-container li.active {
    background-color: var(--bg-bot-cat-active);
    color: var(--tx-bot-cat-active);
    border: 1px solid var(--bg-bot-cat-active);;
    text-decoration: none !important;
}

.barra-categorias .categorias-container ul {
    margin: 0;
    display: flex;
    list-style: none;
    justify-content: space-between;
    height: 100%;
    align-items: center;
    color: white;
    font-family: Montserrat;
    font-weight: 200;
    padding: 15px 0px;
}


.barra-categorias .categorias1-container {
    background-color: var(--bg-subcat-principal);
    height: 72px;
    padding-right: 5px;
    padding-left: 5px;
    border-bottom: 1px solid var(--border-bottom-subcat);
}

.barra-categorias .categorias1-container li {
    background-color: transparent;
    padding: 6px 10px;
    border: 1px solid var(--border-bot-subcat);
    color: var(--tx-bot-subcat);
    min-width: 134px;
    text-align: center;
    /*box-shadow: 1px 1px 4px #00000033;*/
    border-radius: 0px;
    margin-left: 5px;
    margin-right: 5px;
    text-transform: uppercase;
    cursor: pointer;
    white-space: nowrap;
    -webkit-transition: ease .2s;
    -moz-transition: ease .2s;
    -ms-transition: ease .2s;
    -o-transition: ease .2s;
    transition: ease .2s;
}

.barra-categorias .categorias1-container li:hover {
    transform: scale(1.04);
}


.barra-categorias .categorias1-container a {
    text-decoration: none !important;
    outline: none;
}

.barra-categorias .categorias1-container li.active {
    background-color: var(--bg-bot-subcat-active);
    color: var(--tx-bot-subcat-active);
    border: 1px solid var(--bg-bot-subcat-active);
    text-decoration: none !important;
}

.barra-categorias .categorias1-container ul {
    margin: 0;
    padding: 0;
    display: flex;
    list-style: none;
    justify-content: flex-start;
    height: 100%;
    align-items: center;
    color: white;
    font-family: Montserrat;
    font-weight: 200;
}

.barra-categorias .container-limit {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
    overflow-y: hidden;
    -ms-overflow-style: none;
    display: flex;
    mask-image: linear-gradient(to right, transparent, white 8px, white 97%, transparent);
}

.barra-categorias .container-limit::-webkit-scrollbar {
    display: none;
}