.checkout {

}

.checkout .detalle-compra {
    border: 1px solid rgba(39, 36, 54, 0.17);
    padding-left: 25px;
    padding-right: 25px;
}

.checkout .row-producto {
    display: flex;
    align-items: center;
    padding-top: 25px;
    padding-bottom: 35px;
    border-bottom: 1px solid rgba(39, 36, 54, 0.17);
}

@media (max-width: 714px) {
    .checkout .row-producto {
        padding-top: 10px;
        padding-bottom: 10px;
    }
}

.checkout .img-producto {
    min-width: 57px;
    text-align: center;
}

.checkout .row-producto img {
    max-width: 62px;
    max-height: 88px;
}

@media (max-width: 358px) {
    .checkout .row-producto img {
        max-width: 35px;
    }
}

.checkout .detalle-producto {
    margin-left: 30px;
    font-family: Montserrat;
}

@media (max-width: 382px) {
    .checkout .detalle-producto {
        margin-left: 15px;
    }
}

.checkout .detalle-producto h4 {
    font-weight: 200;
    font-size: 18px;
    margin: 0;
}

@media (max-width: 714px) {
    .checkout .detalle-producto h4 {
        font-size: smaller;
        line-height: 12px;
        margin: 3px 0;
    }
}

.checkout .detalle-producto .etiqueta-promo {
    position: relative;
    margin-top: 5px;
}

@media (max-width: 714px) {
    .checkout .detalle-producto .etiqueta-promo {
        font-size: 10px !important;
        padding-left: 5px;
        padding-right: 5px;
        padding-bottom: 5px;
        padding-top: 5px;
        max-width: 141px;
    }
}

.checkout .detalle-producto h5 {
    font-weight: 100;
    margin: 0;
    margin-top: 5px;
    font-size: 13px;
}

@media (max-width: 360px) {
    .checkout .detalle-producto h4 {
        font-size: 14px;
    }

    .checkout .detalle-producto h5 {
        font-size: 10px;
    }
}

.checkout .detalle-producto p {
    font-weight: 100;
    margin: 0;
    margin-top: 32px;
    font-size: 13px;
}

@media (max-width: 714px) {
    .checkout .detalle-producto {
        padding-right: 8px;
    }

    .checkout .detalle-producto p {
        font-size: small !important;
        line-height: normal;
        margin-top: 15px;
    }
}

@media (max-width: 358px) {
    .checkout .detalle-producto p {
        font-size: 10px;
    }
}

.checkout .detalle-producto p span {
    cursor: pointer;
    margin-left: 5px;
    margin-right: 5px;
}

.checkout .precio-producto {
    margin-left: auto;
}

.checkout .precio-producto {
    font-family: Montserrat;
    font-size: 23px;
    font-weight: 100;
}

@media (max-width: 714px) {
    .checkout .precio-producto {
        font-size: large;
    }
}

@media (max-width: 400px) {
    .checkout .precio-producto {
        font-size: 16px;
    }
}

.checkout .row-totales {
    display: flex;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 10px;
    border-bottom: 1px solid rgba(39, 36, 54, 0.17);
}


.checkout .valor-precio {
    margin-left: auto;
    font-family: Montserrat;
    font-size: 23px;
    font-weight: 100;
}

@media (max-width: 400px) {
    .checkout .valor-precio {
        font-size: 16px;
    }
}

.checkout .row-totales {
    border: none;
}

.checkout .row-totales h4 {
    font-weight: 100;
    margin-bottom: 9px;
}

.checkout .row-total h4, .checkout .row-total .valor-precio p {
    font-weight: 400;
}


