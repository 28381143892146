.barra-derecha {
    z-index: 8;
    width: 0px;
    overflow-x: hidden;
    height: 100%;
    position: fixed;
    padding-top: 65px;
    box-shadow: 1px 0px 10px rgba(0, 0, 0, 0.22);
    /*border-left: 1px solid rgba(0, 0, 0, 0.22);*/
    /*border-right: 1px solid rgba(0, 0, 0, 0.22);*/
    background-color: white;
    transition: width .2s ease-in;
    right: 0;
    white-space: nowrap;
}

.barra-derecha.activa {
    width: 360px;
    right: 0;
}

@media (max-width: 714px) {
    .barra-derecha.activa {
        width: 100%;
    }
}

.barra-derecha .container-carrito {
    padding-top: 5px;
}

.barra-derecha .titulo-carrito {
    padding: 0 15px 0 15px;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    border-bottom: 1px solid rgba(0, 0, 0, 0.22);
}

.barra-derecha .titulo-carrito h6 {
    font-family: Montserrat;
    font-weight: 200;
    font-size: medium;
    float: left;
}

.barra-derecha .titulo-carrito .cart {
    width: 100%;
    max-width: 20px;
    fill: black;
    stroke: black;
    stroke-width: 9px;
    margin-left: 10px;
    position: relative;
    top: 3px;
}

.barra-derecha .titulo-carrito .flecha-derecha {
    width: 22px;
    height: 22px;
    float: right;
    margin: 15px;
    cursor: pointer;
}

.barra-derecha .container-productos {
    /*border-bottom: 1px solid rgba(0, 0, 0, 0.22);*/
    max-height: calc(100% - 200px);
    padding: 0 15px 0 15px;
    padding-bottom: 10px;
    overflow-y: auto;
    touch-action: pan-y;
    user-select: none;
    -webkit-user-drag: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

@media (max-width: 714px) {
    .barra-derecha .container-productos {
        max-height: calc(100vh - 240px);
        padding-bottom: 100px;
    }
}

.producto-carrito .cruz-cerrar {
    position: absolute;
    top: 0px;
    right: 8px;
    font-size: 23px;
    color: #0000008a;
    cursor: pointer;
}

.barra-derecha .container-carrito-total {
    position: absolute;
    bottom: 0;
    width: 100%;
    background-color: white;
    box-shadow: 0 -6px 9px -5px #76646461;
    z-index: 999;
}

.barra-derecha .container-carrito-total .precio-total {
    display: flex;
    justify-content: space-between;
    font-family: Montserrat;
    font-weight: 100;
    font-size: 20px;
    padding-top: 10px;
    padding-right: 15px;
    padding-left: 15px;

}

.barra-derecha .container-carrito-total .precio-total p {
    margin: 0
}

.barra-derecha .container-carrito-total .info-precio-futuro {
    /*max-width: 250px;*/
    font-family: Montserrat;
    font-weight: 100;
    font-size: smaller;
}

.barra-derecha .btn-confirmar {
    margin-top: 5px;
}

.barra-derecha .container-botones .encargar {
    white-space: nowrap;
    padding-right: 32px;
    font-weight: 200;
    z-index: 99;
    background-color: var(--bg-btn-confirmar);
}

.barra-derecha .container-botones a:hover, a:focus {
    text-decoration: none;
}

.barra-derecha .container-botones .encargar svg {
    max-width: 15px;
    fill: var(--tx-btn-confirmar);
    top: 2px;
    position: relative;
    stroke: var(--tx-btn-confirmar);
    stroke-width: 18px;
    margin-left: 5px;
}

.barra-derecha .producto .precios-vigentes b {
    font-size: small;
}