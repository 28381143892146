.modal-tools {
    height: 100%;
    width: 100%;
    position: fixed;
    background-color: white;
    z-index: 99999999999;
    top: 0px;
}

.header-modal {
    padding: 15px;
    padding-left: 32px;
}

.modal-volver {
    font-size: 25px;
    color: var(--btn-color-principal);
    margin-top: 15px;
    margin-bottom: 25px;
    cursor: pointer;
}

.lista-filtros-modal {
    padding: 0;
    list-style: none;
}

.lista-filtros-modal:last-child{
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.lista-filtros-modal li {
    padding-top: 25px;
    padding-bottom: 25px;
    padding-left: 32px;
    font-size: large;
    font-family: Montserrat;
    font-weight: 100;
    cursor: pointer;
    border-top: 1px solid rgba(0, 0, 0, 0.15);
    position: relative;
}

.lista-filtros-modal li:last-child {
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}


.lista-filtros-modal {
    margin: 0;
}

.filtros-responsive .lista-filtros-modal li::after {
    content: '\E903';
    position: absolute;
    font-family: Iconos;
    color: var(--bg-nav-principal);
    right: 32px;
    font-size: 10px;
    top: 30px;
}

.filtros-responsive .lista-filtros-modal.down li::after{
    transform: rotate(180deg);
}

.lista-filtros-modal li.selected {
    border-right: 5px solid var(--bg-nav-principal);
    border-left: 5px solid var(--bg-nav-principal);
}

ul.sublista-filtros-modal {
    list-style: none;
    margin: 0;
    padding: 0;
    padding-bottom: 20px;
    padding-top: 20px;
    border-top: 1px solid rgba(0, 0, 0, 0.15);
    /*border-bottom: 1px solid rgba(0, 0, 0, 0.15);*/
    background-color: #fafafa;
    display: none;
}

ul.sublista-filtros-modal li {
    border-bottom: none;
    border-top: none;
}

ul.sublista-filtros-modal li::after {
    display: none;
}

ul.sublista-filtros-modal li:last-child {
    border: none;
}

.modal-body-tool {
    overflow-y: auto;
    height: 100vh;
    padding-bottom: 230px;
}