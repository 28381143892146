@keyframes placeHolderShimmer {
    0% {
        background-position: -468px 0
    }
    100% {
        background-position: 468px 0
    }
}

.skelleton-loader {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-name: placeHolderShimmer;
    animation-name: placeHolderShimmer;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    background: #f6f7f8;
    background: -webkit-gradient(linear, left top, right top, color-stop(8%, #eeeeee), color-stop(18%, #dddddd), color-stop(33%, #eeeeee));
    background: -webkit-linear-gradient(left, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
    background: -o-linear-gradient(left, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
    background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
    background-size: 812px 104px;
}

.p-loader {
    height: 318px;
    min-width: 260px;
    margin: 11px;
    top: 10px;
}

.h2-loader {
    height: 51px;
    width: 30%;
    top: 20px;
    margin-left: 11px;
    display: block!important;
}

.todo-loader {
    height: 100%;
    width: 100% !important;
    top: 0px;
    margin-left: 0px;
    display: block!important;
}

.productos-hm.skelleton {
    max-width: 1200px;
    margin: 0 auto;
    overflow-x: hidden;
}

@media (max-width: 714px) {
    .productos-hm.skelleton {
        max-width: inherit;
    }
}

.skelleton-loader.categorias {
    opacity: 0.5;
}

.skelleton-loader.buscad {
    background: #ffffff;
    background: -webkit-gradient(linear, left top, right top, color-stop(8%, #ffffff), color-stop(18%, #dfdcdc), color-stop(33%, #ffffff));
    background: -webkit-linear-gradient(left, #ffffff 8%, #dfdcdc 18%, #ffffff 33%);
    background: -o-linear-gradient(left, #ffffff 8%, #dfdcdc 18%, #ffffff 33%);
    background: linear-gradient(to right, #ffffff 8%, #dfdcdc 18%, #ffffff 33%);
    background-size: 812px 104px;
    border-radius: 4px;
    width: 290px;
    box-shadow: 0px 0px 7px -1px rgba(0, 0, 0, 0.19);
}

@media (max-width: 714px) {
    .skelleton-loader.buscad {
        width: 100%;
        margin: 7px;
        border: 1px solid #0000000a;
        margin-bottom: 2px;
        height: 250px;
    }
}