.producto-presentacion .swiper-button-next,
.producto-presentacion .swiper-container-rtl .swiper-button-prev {
    right: -7px;
    color: var(--slider-nav-color);
}

.producto-presentacion .swiper-button-prev,
.producto-presentacion .swiper-container-rtl .swiper-button-next {
    left: -7px;
    color: var(--slider-nav-color);
}

.producto-presentacion .swiper-button-prev:after,
.producto-presentacion .swiper-container-rtl .swiper-button-next:after {
    font-size: 24px;
}

.producto-presentacion .swiper-button-next:after,
.producto-presentacion .swiper-container-rtl .swiper-button-prev:after {
    font-size: 24px;
}


.producto-presentacion .swiper-button-prev,
.producto-presentacion .swiper-button-next {
    opacity: 0;
}


.producto-presentacion .container-img:hover .swiper-container .swiper-button-prev,
.producto-presentacion .container-img:hover .swiper-container .swiper-button-next {
    opacity: 1;
}

@media (max-width: 714px) {
    .carrousel-buttons {
        display: none !important;
    }
}