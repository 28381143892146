.faqs {
    padding-top: 110px;
    padding-bottom: 140px;
}

.titulo-seccion.titulo-black {
    color: black;
}

.titulo-seccion.titulo-black i {
    color: var(--tx-highlight);
}

.faqs ul {
    margin-top: 35px;
    list-style: none;
    padding-right: 15px;
}

.faqs li {
    margin-top: 25px;
    padding-left: 16px;
}

.faqs .pregunta {
    position: relative;
}

.faqs .pregunta:before {
    content: '';
    width: 12px;
    height: 12px;
    display: block;
    background-color: var(--bullets-faqs);
    position: absolute;
    border-radius: 100%;
    top: 5px;
    left: -23px;
}

.faqs .pregunta p {
    font-family: Montserrat;
    font-weight: 300;
}

.faqs .respuesta {
    margin-bottom: 15px;
}

.faqs .respuesta p {
    font-family: Montserrat;
    font-weight: 100;
}