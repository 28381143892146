.mis-pedidos table {
    width: 100%;
    border-collapse: collapse;
    background-color: #f5f5f5;
    max-width: 1040px;
    border-radius: 4px;
    box-shadow: 0px 0px 7px -1px rgba(0, 0, 0, 0.19);
    font-family: Montserrat;
    font-weight: 100;
}

/* Zebra striping */
.mis-pedidos tr:nth-of-type(odd) {
    background: white;
}

.mis-pedidos th {
    color: #1d1d1d;
    height: 75px;
    padding-left: 25px;
    font-weight: bold;
    border: none;
}

.mis-pedidos th:first-child {
    border-top-left-radius: 4px;
}

.mis-pedidos th:last-child {
    border-top-right-radius: 4px;
}

.mis-pedidos td, th {
    padding: 15px;
    padding-left: 22px;
    border-top: 2px solid #ccccccc9;
    text-align: left;
}

@media (max-width: 900px) {

    .mis-pedidos td, th {
        padding-left: 15px;
    }

    /* Force table to not be like tables anymore */
    .mis-pedidos table.tabla-ppal,
    .mis-pedidos table.tabla-ppal thead.thead-tabla-ppal,
    .mis-pedidos tbody.body-tabla-ppal,
    .mis-pedidos table.tabla-ppal thead.thead-tabla-ppal th,
    tr.tr-tabla-ppal td, thead.thead-tabla-ppal tr.tr-tabla-ppal {
        display: block;
        background-color: transparent;
    }

    /* Hide table headers (but not display: none;, for accessibility) */
    .mis-pedidos .tabla-ppal thead.thead-tabla-ppal tr {
        position: absolute;
        top: -9999px;
        left: -9999px;
    }

    .mis-pedidos tr.tr-tabla-ppal {
        /*border: 1px solid #ccc;*/
        display: block;
        border-top: 25px solid var(--bg-nav-principal);
    }

    .mis-pedidos tr.tr-tabla-ppal:first-child {
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
    }

    .mis-pedidos tr.tr-tabla-ppal:last-child {
        border-bottom-left-radius: 4px;
        border-bottom-top-right-radius: 4px;
    }

    .mis-pedidos tr.tr-tabla-ppal td {
        /* Behave  like a "row" */
        border: none;
        border-bottom: 1px solid #eee;
        position: relative;
        padding-left: 50%;
    }

    .mis-pedidos tr.tr-tabla-ppal td:before {
        /* Now like a table header */
        position: absolute;
        /* Top/left values mimic padding */
        left: 24px;
        width: 45%;
        padding-right: 10px;
        white-space: nowrap;
    }

    /*
    Label the data
    */
    .mis-pedidos tr.tr-tabla-ppal td:before {
        font-weight: bold;
    }

    .mis-pedidos tr.tr-tabla-ppal td:nth-of-type(1):before {
        content: "Nº Pedido";
    }

    .mis-pedidos tr.tr-tabla-ppal td:nth-of-type(2):before {
        content: "Fecha de pedido";
    }

    .mis-pedidos tr.tr-tabla-ppal td:nth-of-type(3):before {
        content: "Direción entrega";
    }

    .mis-pedidos tr.tr-tabla-ppal td:nth-of-type(4):before {
        content: "Cant. Items";
    }

    .mis-pedidos tr.tr-tabla-ppal td:nth-of-type(5):before {
        content: "Importe";
    }

    .mis-pedidos tr.tr-tabla-ppal td:nth-of-type(6):before {
        content: "Estado";
    }

    .mis-pedidos tr.tr-tabla-ppal td:nth-of-type(7):before {
        content: "Detalle";
    }


    .mis-pedidos tr.tr-tabla-ppal .body-modal td:before {
        content: "";
    }

    .mis-pedidos tr.tr-tabla-ppal .body-modal tr {
        display: table-row;
    }

    .mis-pedidos tr.tr-tabla-ppal .body-modal td {
        padding-top: 8px;
        padding-bottom: 8px;
        display: table-cell;
        padding-left: 10px;
        padding-right: 10px;
    }

    .mis-pedidos .body-modal table{
        display: table;
    }
    .mis-pedidos .body-modal table thead,
    .mis-pedidos .body-modal tbody,
    .mis-pedidos .body-modal table thead th,
    .body-modal tr td, .body-modal thead tr {
        border: none;
        border-bottom: 1px solid #00000042;
    }

    .body-modal td, .body-modal th {
        border: 1px solid #ddd;
        padding: 8px;
    }

    .body-modal table{
        font-size: 11px;
    }
}

table.tabla-detalle-pedido {
    overflow-x: scroll;
}

.detalle-pedido {
    display: none;
}

.detalle-pedido.showDetalle {
    display: table-row;
}

@media (max-width: 900px) {
    .detalle-pedido.showDetalle {
        display: block;
        overflow-x: scroll;
    }
}

.mis-pedidos .body-modal th{
    padding-left: 15px;
    padding-right: 15px;
}

.mis-pedidos .body-modal td{
    padding: 8px;
}

.loader-invisible th{
    background: #E9E9E9;
    padding: 15px;
    height: fit-content;
}



@-webkit-keyframes placeHolderShimmer {
    0% {
        background-position: -468px 0;
    }
    100% {
        background-position: 468px 0;
    }
}

@keyframes placeHolderShimmer {
    0% {
        background-position: -468px 0;
    }
    100% {
        background-position: 468px 0;
    }
}
.animated-background, .parrafoInvisible {
    -webkit-animation-duration: 1.25s;
    animation-duration: 1.25s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-name: placeHolderShimmer;
    animation-name: placeHolderShimmer;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    background: #F6F6F6;
    background: -webkit-gradient(linear, left top, right top, color-stop(8%, #F6F6F6), color-stop(18%, #F0F0F0), color-stop(33%, #F6F6F6));
    background: linear-gradient(to right, #F6F6F6 8%, #F0F0F0 18%, #F6F6F6 33%);
    background-size: 800px 104px;
    height: 28px;
    position: relative;
    margin: 0;
}