.listado-direccionesEntrega .direccionFrecuente {
    background-color: white;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .15);
    border-radius: 4px;
    padding: 20px;
    font-family: Montserrat;
    font-weight: 100;
    display: flex;
    align-items: center;
    cursor: pointer;
    border-left: 6px solid transparent;
    margin-top: 8px;
}

.listado-direccionesEntrega .direccionFrecuente-loading {
    background-color: white;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .15);
    border-radius: 4px;
    padding: 0px;
    font-family: Montserrat;
    font-weight: 100;
    display: flex;
    align-items: center;
    height: 60px;
}

.listado-direccionesEntrega .direccionFrecuente:hover {
    background-color: #f5f5f5;
}

.listado-direccionesEntrega .direccionFrecuente div:first-child {
    flex-grow: 1;
}

.listado-direccionesEntrega .direccionFrecuente .fecha-entrega {
    margin-top: 5px;
    color: var(--btn-color-principal);
}

.listado-direccionesEntrega .direccionFrecuente .entrega-confirmar {
    margin-top: 5px;
    color: #9e1f22;
}

.listado-direccionesEntrega .direccionFrecuente .costo-envio {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 68px;
    justify-content: center;
}

.listado-direccionesEntrega .direccionFrecuente .costo-envio.gratis p {
    color: rgb(95, 198, 26);
}

.listado-direccionesEntrega .direccionFrecuente.active {
    border-left: 6px solid var(--btn-color-principal);
}

.listado-direccionesEntrega .direccionFrecuente .nueva {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Montserrat;
    font-weight: 100;
}

.listado-direccionesEntrega .direccionFrecuente .nueva p {
    margin: 0;
    margin-right: 5px;
}

.listado-direccionesEntrega .mensaje {
    display: none;
    color: #9e1f22;
    margin-bottom: 10px;
}

.listado-direccionesEntrega .mensaje.visible {
    display: block;
    color: #9e1f22;
}