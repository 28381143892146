.zona-entregas {
    background-color: white;
    z-index: -6;
}

.titulo-seccion.subtitulo-home {
    color: var(--tx-btn-contact);
    font-weight: bold;
    font-size: 17px;
    text-align: left;
}

.containerZonaEntrega > :nth-child(1){
    flex:1 1 50%;
}

.containerZonaEntrega > :nth-child(2){
    flex: 1 1 50%;
    padding: 30px 60px 30px 60px;
}

@media(max-width: 820px){
    .containerZonaEntrega{
        flex-direction: column-reverse;
    }
    .containerZonaEntrega > :nth-child(1){
        flex:1 1 100%;
    }

    .containerZonaEntrega > :nth-child(2){
        flex: 1 1 100%;
        padding: 30px 15px;
    }

}

.containerZonaEntrega svg{
    fill:var(--label-campo-texto);
}

.containerZonaEntrega .mensajeLugarEntrega{
    font-size: 17px;
    font-weight: 200;
    font-family: Montserrat;
    display: none;
    color: var(--tx-highlight);
}

.containerZonaEntrega .mensajeLugarEntrega.habilitado{
    display: inline;
}

.containerZonaEntrega .mensajeLugarEntrega svg{
    margin-right: 15px;
}

/*.zona-entregas .container-zona {*/
    /*padding-left: 55px;*/
    /*padding-top: 5px;*/
    /*padding-bottom: 80px;*/
/*}*/

/*.zona-entregas .container-mapa img {*/
    /*width: 100%;*/
    /*height: 100%;*/
/*}*/

/*.zona-entregas .campoTexto{*/
    /*max-width: 335px;*/
/*}*/

/*.zona-entregas .campoTexto input {*/
    /*background: none;*/
    /*color: white;*/
    /*padding-left: 20px;*/
    /*padding-top: 4px;*/
/*}*/

/*.zona-entregas .campoTexto input:placeholder-shown ~ .campoTexto-label {*/
    /*cursor: text;*/
    /*font-style: italic;*/
    /*color: white;*/
/*}*/

/*.zona-entregas .campoTexto .campoTexto-linea {*/
    /*border-bottom: 2px solid white;*/
/*}*/

/*.zona-entregas .marker-map {*/
    /*width: 22px;*/
    /*height: 22px;*/
    /*margin: 0;*/
    /*padding: 0;*/
    /*position: absolute;*/
    /*top: 0;*/
    /*fill: white;*/
/*}*/

.info-horarios{
    margin-bottom: 10px;
}

.info-horarios span{
    font-size: 15px;
    font-family: Montserrat;
}

.info-horarios svg{
    color: var(--tx-highlight);
}