.nav-bar {
    background-color: var(--bg-nav-principal);
    height: 65px;
    width: 100%;
    position: fixed;
    top: 0;
    box-shadow: 0px 0px 11px #00000078;
    z-index: 9;
}

.container-nav {
    display: flex;
    align-items: center;
    height: 100%;
    justify-content: space-between;
    padding-right: 20px;
    padding-left: 20px;
}

.container-logo .logo {
    width: 100%;
    max-height: 45px;
    max-width: 106px;
    stroke: var(--stroke-logo);
    stroke-width: 1px;
    position: relative;
    align-self: center;
    display: flex;
}

.container-logo a {
    display: block;
}

.container-menu {
    display: block;
}

.container-nav .item-menu-visible-res {
    display: none;
}

.container-menu ul {
    display: flex;
    list-style: none;
    font-family: Montserrat;
    font-weight: 100;
    margin-bottom: 0;
    align-items: center;
}

@media (max-width: 375px) {
    .container-menu ul {
        padding-left: 0 !important;
    }
}

.container-menu li {
    text-align: center;
}

.container-menu li a {
    color: var(--tx-nav-principal);
    margin-left: 8px;
    margin-right: 8px;
    display: flex;
    align-items: center;
    text-decoration: none;
    transition: all linear 100ms;
}

.container-menu .button-signup {
    border: 1px solid var(--tx-nav-principal);
    padding: 3px 0px;
    font-weight: 200;
    border-radius: 4px;
    margin-right: 10px;
}


@media (max-width: 900px) {
    .container-menu li a {
        margin-left: 0px;
        margin-right: 0px;
    }
}

.container-menu li a:hover {
    color: var(--btn-color-principal);
    filter: brightness(180%);
    text-decoration: none;
}

.cart {
    width: 100%;
    max-width: 20px;
    fill: var(--tx-nav-principal);
    margin-left: 10px;
    stroke: var(--tx-nav-principal);
    stroke-width: 16px;
}

.barra-separadora-menu {
    height: 35px;
    padding-right: 15px;
    align-items: center;
    display: flex;
    border-right: 1px solid white;
}

.nav-bar.black {
    background-color: white;
}

.nav-bar.black .container-menu li a {
    color: var(--bg-nav-principal);
}

.nav-bar.black .logo path, .nav-bar.black .logo circle {
    fill: var(--bg-nav-principal);
}

.burguer-menu {
    margin-left: 5px;
    display: flex;
    width: 20px;
    height: 20px;
    flex-direction: row;
    cursor: pointer;
    flex-wrap: wrap;
    margin-top: 7px;
}

.burguer-menu div {
    background-color: var(--tx-nav-principal);
    width: 20px;
    height: 2px;
}

.burguer-menu div:last-child {
    width: 13px;
    position: relative;
    left: 7px;
}

@media (max-width: 714px) {
    .container-menu .item-menu {
        display: none;
    }

    .container-nav .item-menu-visible-res {
        display: block;
    }
}

.menu-res {
    position: fixed;
    overflow-x: hidden;
    top: 0;
    width: 0;
    height: 100%;
    background-color: white;
    right: 0;
    z-index: 99999999;
    padding-top: 26px;
    /*padding-left: 15px;*/
    /*padding-right: 15px;*/
    box-shadow: 0px 0px 11px #00000078;
    max-width: 30%;
    transition: width .2s ease-in;
    border: none;
    white-space: nowrap;
}

@media (max-width: 745px) {
    .menu-res {
        max-width: 80%;
    }
}


.menu-res.show {
    display: block;
    width: 100%;
    right: 0;
}

.titulo-menu-res {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.titulo-menu-res h6 {
    font-family: Montserrat;
    font-size: 20px;
    font-weight: 400;
    margin: 0;
}

.container-menu-res {
    padding-right: 15px;
    padding-left: 15px;
}

.container-menu-res ul {
    list-style-type: none;
    margin-left: 0;
    padding-left: 0px;
}

.container-menu-res ul li {
    padding-top: 15px;
    padding-bottom: 15px;
    font-family: Montserrat;
    font-weight: 300;
    cursor: pointer;
}

.container-menu-res ul li a {
    color: var(--tx-menu-desplegable) !important;
}

.container-menu-res .barra-separadora-menu {
    height: unset;
    border: none;
}

.container-menu-res .item-menu {
    border-bottom: 1px solid rgba(0, 0, 0, 0.32);
}

.container-menu-res .item-menu:first-child {
    border-top: 1px solid rgba(0, 0, 0, 0.32);
}

.container-menu-res .item-menu:last-child {
    border-top: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.32);
}

.nav-bar .cant-items {
    background-color: var(--tx-nav-principal);
    color: var(--bg-nav-principal);
    border-radius: 100%;
    width: 15px;
    height: 15px;
    position: absolute;
    top: -6px;
    right: -11px;
    transition: all .2s ease-in-out;
}

.nav-bar .cant-items.animate {
    transform: scale(2.5);
}

.nav-bar .cant-items p {
    margin: 0;
    padding: 0;
    font-size: 10px;
}

.container-menu .span-precio-nav {
    color: var(--tx-nav-principal);
    padding-left: 5px;
    white-space: nowrap;
}

.nav-bar .buscador-container {
    justify-self: self-start;
    flex: 1;
    padding-left: 25px;
}

.nav-bar .buscador-container input {
    width: 350px;
    height: 38px;
    border: none;
    border-radius: 3px;
    outline: none;
    padding: 8px;
    padding-left: 28px;
    padding-right: 67px;
    box-shadow: 1px 4px 7px var(--shadow-input);
}

.nav-bar .buscador-input {
    position: relative;
    display: inline-block;
}

.nav-bar .buscador-lupa {
    position: absolute;
    top: 11px;
    left: 8px;
}

.nav-bar .buscador-container button {
    position: absolute;
    right: 5px;
    top: 5px;
    border: none;
    background-color: var(--btn-color-principal);
    text-shadow: none;
    color: var(--btn-tx-principal);
    font-family: Montserrat;
    font-weight: 300;
    font-size: 12px;
    padding-top: 5px;
    padding-bottom: 6px;
    border-radius: 2px;
}


.responsive.buscador-container {
    justify-self: self-start;
    flex: 1;
    position: fixed;
    z-index: 8;
    top: 65px;
    width: 100%;
    background-color: var(--bg-nav-secundario);
    padding: 10px;
}

.responsive.buscador-container input {
    width: 100%;
    height: 38px;
    border: none;
    border-radius: 3px;
    outline: none;
    padding: 8px;
    padding-left: 28px;
    padding-right: 67px;
}

.responsive.buscador-container .buscador-input {
    position: relative;
    display: inline-block;
    width: 100%;
}

.responsive.buscador-container .buscador-lupa {
    position: absolute;
    top: 11px;
    left: 8px;
}

.responsive.buscador-container button {
    position: absolute;
    right: 5px;
    top: 5px;
    border: none;
    background-color: #1ba1db;
    text-shadow: none;
    color: white;
    font-family: Montserrat;
    font-weight: 300;
    font-size: 12px;
    padding-top: 5px;
    padding-bottom: 6px;
    border-radius: 2px;
}

.nav-bar .buscador-container button.search-clear, .responsive.buscador-container button.search-clear {
    right: 5px;
    top: 11px;
    color: white;
    background-color: #b4adad;
    border-radius: 50%;
    width: 16px;
    padding: 0;
    height: 16px;
    margin: 0;
    font-size: 9px;
    outline: none;
}


.nav-bar span.moneda {
    font-size: xx-small;
    margin-right: 3px;
}

.nav-bar span.decPart {
    font-size: 9px;
    vertical-align: super;
    top: 1px;
    position: relative;
}

@media (max-width: 320px) {
    .nav-bar .container-usuario span.acceso {
        display: none;
    }
}

.separadores {
    border-left: 1px solid var(--nav-separadores);
}

.menu-icon {
    fill: var(--tx-nav-principal);
    stroke: var(--tx-nav-principal);
    color: var(--tx-nav-principal);
}

.li-lupa {
    display: block;
    fill: var(--tx-nav-principal);
    width: 18px !important;
    height: 18px !important;
    margin-right: 10px;
    margin-left: 7px;
}

@media (min-width: 714px) {
    .buscador-container.responsive {
        display: none !important;
    }

    .li-lupa {
        display: none;
    }
}

li.hidden-900 {
    display: block;
}

li.show-900 {
    display: none;
}

@media (max-width: 900px) {
    li.show-900 {
        display: block;
    }

    li.hidden-900 {
        display: none;
    }
}

.titulo-menu-login {
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 1px solid rgba(0, 0, 0, 0.32);
    padding-top: 15px;
    padding-bottom: 15px;
}

.titulo-menu-login a {
    margin-left: 10px;
    margin-right: 10px;
    color: var(--tx-menu-desplegable) !important;
    font-family: Montserrat;
    text-transform: uppercase;
}

@media (max-width: 714px) {
    .titulo-menu-login a {
        font-size: 12px;
    }
}

.titulo-menu-login a.registrate {
    border: 1px solid var(--tx-menu-desplegable) !important;
    padding: 8px 10px;
    border-radius: 4px;
}

.arrow-menu {
    width: 22px;
    height: 22px;
}

.menu-usuario {
    padding-top: 9px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 28px;
}

.menu-usuario img {
    max-width: 70px;
    max-height: 70px;
    border-radius: 100%;
    border: 2px solid var(--bg-nav-principal);
    margin-right: 10px;
}

.perfil-usuario {
    margin-left: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.perfil-usuario p {
    font-family: Montserrat;
    color: var(--tx-menu-desplegable);
    font-size: 18px;
    font-weight: 200;
    margin-bottom: 3px;
}


.perfil-usuario button {
    background: none;
    box-shadow: none;
    border: none;
    background-color: var(--btn-color-principal);
    color: var(--btn-tx-principal);
    border-radius: 4px;
    font-family: Montserrat;
    font-weight: 700;
    padding-top: 4px;
    padding-bottom: 2px;
    padding-left: 13px;
    padding-right: 13px;
    outline: none;
}

@media (max-width: 714px) {
    .perfil-usuario p {
        font-size: 15px;
    }

    .perfil-usuario button {
        font-size: 12px;
    }
}

.selector-moneda {
    width: 100%;
}