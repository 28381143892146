.micuenta {
    display: flex;
}

.barra-micuenta {
    min-height: 600px;
    padding-top: 35px;
    background-color: #f5f5f5;
    width: 325px;
    padding-left: 45px;
    font-family: Montserrat;
    color: #545459;
}


.titulo-micuenta {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 0;
    color: #545459;
}

p.titulo-micuenta + span {
    font-weight: 100;
}

.barra-micuenta ul {
    margin-top: 25px;
    padding-left: 0px;
    list-style: none;
}

.barra-micuenta li {
    margin-top: 9px;
    margin-bottom: 9px;
    display: flex;
    align-items: center;
}

.barra-micuenta li a {
    margin-left: 8px;
    color: #545459;
    font-weight: 200;
}

.btn-opciones {
    display: none;
}

@media (max-width: 900px) {
    .micuenta {
        display: block;
    }

    .barra-micuenta {
        min-height: inherit;
        background-color: white;
        width: 100%;
        display: block;
        max-height: 0px;
        overflow: hidden;
        transition: all .5s ease-in;
        position: relative;
        padding-top: 55px;
    }

    .barra-micuenta.open {
        max-height: 500px;
        padding-bottom: 68px;
    }

    .btn-opciones {
        display: block;
        position: absolute;
        bottom: 14px;
        left: 47%;
        font-size: 24px;
        color: var(--bg-nav-principal);
        transition: all .5s ease-in-out;
    }

    .btn-opciones.rotado {
        transform: rotate(180deg);
    }
}

.container-micuenta {
    padding: 15px;
    padding-bottom: 75px;
    background-color: #f5f5f5;
    width: 100%;
}

.titulo-configuracion {
    background-color: white;
    max-width: 1040px;
    border-radius: 4px;
    padding-left: 10px;
    box-shadow: 0px 0px 7px -1px rgba(0, 0, 0, 0.19);
    margin-top: 15px;
    margin-bottom: 15px;
}

.titulo-configuracion h2 {
    margin: 0;
    padding: 15px;
    font-family: Montserrat;
    font-weight: 700;
    padding-top: 22px;
}