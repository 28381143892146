.react-autosuggest__container {
    position: relative;
}

.react-autosuggest__input {
    border: none;
    width: calc(100% - 26px) !important;
    font-size: 17px;
    font-family: Montserrat;
    font-weight: 100;
    margin-left: 10px;
    transition: border-color 0.2s;
    padding-left: 5px;
    background-color: transparent;
    color: black !important;
}

.react-autosuggest__input--focused {
    outline: none;
}

.react-autosuggest__input--open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    color: #0a0f15;
}

.react-autosuggest__suggestions-container {
    display: none;
}

.react-autosuggest__suggestions-container--open {
    display: block;
    position: absolute;
    top: 51px;
    width: 250px;
    border: 1px solid #aaa;
    background-color: #fff;
    font-family: Helvetica, sans-serif;
    font-weight: 300;
    font-size: 16px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    z-index: 9;
    text-align: left;
}

.react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.react-autosuggest__suggestion {
    cursor: pointer;
    padding: 10px 20px;
}

.react-autosuggest__suggestion--highlighted {
    background-color: #ddd;
}

.autosuggest-container {
    margin: 15px 0;
    width: 100%;
}

.autosuggest-container label {
    position: relative;
    display: block;
    width: 100%;
    min-height: 45px;
}

.autosuggest-container .placeholder {
    position: absolute;
    display: block;
    top: 20px;
    left: 0px;
    z-index: 2;
    font-size: 16px;
    transition: all 200ms ease-in-out;
    width: 100%;
    cursor: text;
    font-family: Montserrat;
    font-weight: 100;
    text-align: left;
}

.autosuggest-container input,
.autosuggest-container textarea {
    position: absolute;
    top: 15px;
    left: 0px;
    z-index: 1;
    width: 100% !important;
    font-size: 16px;
    border: 0;
    border-bottom: 1px solid grey;
    transition: border-color 200ms ease-in-out;
    outline: none;
    padding: 0;
    margin: 0;
}

.autosuggest-container textarea {
    min-height: 30px;
    padding: 8px 0;
}

.autosuggest-container input {
    height: 30px;
}

.autosuggest-container input:focus,
.autosuggest-container textarea:focus {
    border-bottom: 2px solid var(--label-campo-texto);
}

.autosuggest-container .react-autosuggest__container:focus-within + .placeholder,
.autosuggest-container .react-autosuggest__container:valid + .placeholder,
.autosuggest-container textarea:focus + .placeholder {
    top: 0;
    cursor: inherit;
    font-size: 14px;
    color: var(--label-campo-texto);
}

.autosuggest-container .placeholder.show {
    top: 0;
    cursor: inherit;
    font-size: 14px;
}