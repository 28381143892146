.buscador-desplegable {
    position: absolute;
    top: 65px;
    left: 0px;
    max-width: 700px;
    width: 100%;
    overflow-y: scroll;
    max-height: 80vh;
    background-color: white;
    box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.31);
    display: none;
}

@media (max-width: 714px) {
    .buscador-desplegable {
        top: 123px;
        max-height: 100vh;
        height: 100vh;
        padding-bottom: 225px;
    }
}

.buscador-desplegable.activo {
    display: inline;
}

.container-buscador-desplegable {
    padding: 15px;
    padding-top: 25px;
}

.buscador-desplegable .container-ver-resultados button {
    position: relative;
    margin: 3px auto;
    display: block;
}

.buscador-desplegable .container-letras-encontradas h6 {
    font-family: Montserrat;
    font-weight: 200;
    background-color: #8080801c;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 8px;
}

.buscador-desplegable .container-letras-encontradas ul {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0px 10px;
    flex-wrap: wrap;
}

.buscador-desplegable .container-letras-encontradas li {
    margin: 0 5px;
    font-size: 14px;
    font-weight: 500;
}