.container-aviso-especial {
    position: fixed;
    bottom: 15px;
    width: 100%;
    text-align: center;
    z-index: 7;
    margin: 0 auto;
}

.mensaje-especial {
    background-color: var(--bg-etiqueta);
    width: fit-content;
    font-weight: 200;
    margin: 0 auto;
    box-shadow: 3px 3px 6px #00000061;
    border-radius: 4px;
    display: flex;
    padding-right: 15px;
}

.mensaje-especial .cruz-cerrar {
    align-self: center;
    justify-self: center;
    color: white;
    font-size: 18px;
    text-shadow: 1px 0px 3px #00000073;
    padding: 0;
    margin: 0;
    cursor: pointer;
    line-height: normal;
    font-weight: 400;
}


.mensaje-especial p {
    margin: 0;
    padding: 15px;
    font-family: Montserrat;
    color: white;
}

@media (max-width: 669px) {
    .container-aviso-especial {
        bottom: 55px;
    }

    .mensaje-especial {
        max-width: 70%;
    }

    .mensaje-especial p {
        font-size: 12px;
    }


}