.direcionEntrega .container-limit {
    max-width: 700px;
}

@media (max-width: 716px) {
    .direcionEntrega .container-limit {
        margin-left: 5px;
        margin-right: 5px;
    }
}

.container-direccionEntrega-form {
    background-color: white;
    border-radius: 4px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .2);
    padding: 0 32px;
    padding-top: 10px;
    padding-bottom: 30px;
}

@media (max-width: 716px) {
    .container-direccionEntrega-form {
        padding: 22px;
        padding-top: 0px;
    }
}

.container-direccionEntrega-form .fila {
    display: flex;
    justify-content: space-between;
}

@media (max-width: 714px) {
    .container-direccionEntrega-form .fila {
        flex-wrap: wrap;
    }
}

.container-direccionEntrega-form .fila div[id*="input-"],
.container-direccionEntrega-form .autosuggest-container {
    width: 100%;
    padding-right: 14px;
}


.direcionEntrega .container-botones {
    display: flex;
    justify-content: space-evenly;
    margin-top: 80px;
    margin-bottom: 100px !important;
}

.direcionEntrega .container-botones button {
    background: none;
    border: none;
    box-shadow: none;
    text-shadow: none;
    background-color: var(--bg-btn-confirmar);
    color: var(--tx-btn-confirmar);
    font-family: Montserrat;
    font-weight: 200;
    padding-top: 8px;
    padding-bottom: 7px;
    border-radius: 48px;
    width: 45%;
}

.direcionEntrega .container-botones button:focus {
    outline: none;
}

@media (max-width: 716px) {
    .direcionEntrega .titulo-seccion.subtitulo-checkout {
        font-size: x-large;
    }
}
