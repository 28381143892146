@import "Fonts.css";

html, * {
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

}

html {
    /*margin-top: 200px;*/
    height: 100%;
    /* Allows you to scroll below the viewport; default value is visible */
    /*overflow-y: scroll;*/

    /* To smooth any scrolling behavior */
    -webkit-overflow-scrolling: touch;
}

body {
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 100%;
    /* Allows you to scroll below the viewport; default value is visible */
    /*overflow-y: scroll;*/

    /* To smooth any scrolling behavior */
    -webkit-overflow-scrolling: touch;
}
body.no-scroll {
    overflow-y: hidden;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


.container-limit {
    max-width: 1200px;
    margin: 0 auto;
    height: 100%;
    position: relative;
}

.container-limit2 {
    max-width: 820px;
    margin: 0 auto;
    height: 100%;
}

.container-doble {
    display: flex;
    flex-wrap: wrap;
    height: 100%;
}

.col-6 {
    width: 50%;
}

.col-6-fixed {
    width: 440px;
}

.col-3 {
    width: 40%;
}

.col-9 {
    width: 60%;
}

@media (max-width: 714px) {
    .col-6 {
        width: 100%;
    }

    .col-3 {
        width: 100%;
    }

    .col-9 {
        width: 100%;
    }
}

.titulo-seccion {
    font-family: Montserrat;
    font-size: 30px;
    font-weight: 200;
    color: #282538;
    text-align: center;
    margin-top: 50px;
    margin-bottom: 40px;
}

.show-714 {
    display: none !important;
}

@media (max-width: 714px) {
    .titulo-seccion {
        font-size: 20px;
    }

    .hidden-714 {
        display: none !important;
    }

    .show-714 {
        display: block !important;
    }
}

@media (max-width: 900px) {
    .hidden-900 {
        display: none;
    }

    .show-900 {
        display: block !important;
    }
}

.btn-confirmar {
    display: block;
    margin: 40px auto;
    background: none;
    border: none;
    text-shadow: none;
    background-color: var(--bg-btn-confirmar);
    color: var(--tx-btn-confirmar);
    font-family: Montserrat;
    font-weight: 100;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-right: 15px;
    padding-left: 15px;
    border-radius: 50px;
    outline: none;

}

.btn-confirmar:active, .btn-confirmar:hover {
    outline: none;
}
