.producto-carrito {
    /*padding-bottom: 15px;*/
    border-bottom: 1px solid rgba(0, 0, 0, 0.22);
}

.producto-carrito:last-child {
    border-bottom: none;
}

.producto-carrito .container-info-producto {
    padding-left: 15px;
    width: 100%;
}

.producto-carrito.producto-individual .container-info-producto {
    padding-top: 7px;
    padding-bottom: 10px;
}

.producto-carrito .producto .container-img img {
    max-height: 110px;
    max-width: 80px;
    object-fit: contain;
    padding-right: 10px;
    margin-bottom: 35px;
    padding-top: 8px;
    padding-bottom: 8px;
}

.producto-carrito.producto-individual .producto .container-img img {
    margin-bottom: 0px;
}

.producto-carrito .producto {
    padding: 0;
    display: flex;
    align-items: center;
    width: 100%;
    position: relative;
    box-shadow: none;
    margin-bottom: 3px;
    margin-top: 3px;
}

@media (max-width: 714px) {
    .producto-carrito .producto {
        margin-top: 5px;
        margin-bottom: 5px;
    }
}

.producto-carrito .producto:hover {
    transform: none;
}

.producto-carrito .container-detalle p {
    text-align: left !important;
}

.producto-carrito .nombre-producto p {
    font-size: small;
    padding-right: 30px;
}

.producto-carrito .detalle-producto p {
    font-size: smaller;
    max-width: 171px;
}

.producto-carrito .precios-vigentes b {
    font-size: small;
}

.producto-carrito .container-precios {
    max-width: 225px;
}

.producto-carrito .producto .container-info p {
    text-align: left;
}

.producto-carrito .producto .barra-progreso {
    padding-left: 0;
}

.producto-carrito .producto .precios-vigentes {
    align-items: center;
}

.producto-carrito.producto-individual .producto .precios-vigentes {
    margin-top: 5px;
    margin-bottom: 5px;
}

.producto-carrito .producto .barra-progreso {
    padding-top: 10px;
    padding-bottom: 8px;
}

.producto-carrito .producto .barra-progreso .guia-barra {
    height: 5px;

}

.producto-carrito .container-cantidad {
    display: flex;
    flex-wrap: nowrap;
}

.producto-carrito .etiqueta-promo {
    position: relative;
}

/* Hide HTML5 Up and Down arrows. */
.producto-carrito .container-cantidad input[type="number"]::-webkit-outer-spin-button, input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.producto-carrito .container-cantidad input[type="number"] {
    -webkit-appearance: none;
    -moz-appearance: textfield;
    border-radius: 0;
}

.producto-carrito .container-cantidad .btn-restar, .producto-carrito .container-cantidad .btn-sumar,
.producto-presentacion .container-cantidad .btn-restar, .producto-presentacion .container-cantidad .btn-sumar {
    border: 1px solid rgba(0, 0, 0, 0.22);
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
    font-family: Montserrat;
    font-size: 16px;
    padding: 0;
    padding-left: 10px;
    padding-right: 9px;
    cursor: pointer;
    color: rgba(0, 0, 0, 0.72);
}

/*.producto.combo .btn-restar, .producto.combo .btn-sumar {*/
/*    padding-left: 20px !important;*/
/*    padding-right: 20px !important;*/
/*}*/

.producto-carrito .container-cantidad .btn-sumar, .producto-presentacion .container-cantidad .btn-sumar {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
}

.producto-carrito .container-cantidad input, .producto-presentacion .container-cantidad input {
    width: 38px;
    border: 1px solid rgba(0, 0, 0, 0.22);
    border-left: none;
    border-right: none;
    text-align: center;
    outline: none;
    font-weight: 100;
    box-shadow: none;
}


.producto-carrito .cantidad-precio {
    display: flex;
    justify-content: space-between;
    font-family: Montserrat;
}

.producto-carrito .container-precio {
    margin: 0;
    font-weight: 100;
    font-size: larger;
    padding-right: 15px;
}

.producto-carrito .container-precio p {
    margin: 0;
    margin-bottom: 2px;
}

.producto-carrito.producto-individual .precios-vigentes b {
    font-size: 14px !important;
    font-weight: 100;
    padding: 0;
}

.producto-carrito.producto-individual .producto .container-img {
    min-height: unset !important;
}

.producto-carrito .producto .container-img {
    margin-bottom: 0px;
}

.producto .container-cantidad {
    display: flex;
    flex-wrap: nowrap;
}

/* Hide HTML5 Up and Down arrows. */
.producto .container-cantidad input[type="number"]::-webkit-outer-spin-button, input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.producto .container-cantidad input[type="number"] {
    -webkit-appearance: none;
    -moz-appearance: textfield;
    border-radius: 0;
}

.producto .container-cantidad .btn-restar, .producto .container-cantidad .btn-sumar,
.producto-presentacion .container-cantidad .btn-restar, .producto-presentacion .container-cantidad .btn-sumar {
    border: 1px solid rgba(0, 0, 0, 0.22);
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
    font-family: Montserrat;
    font-size: 16px;
    padding: 0;
    padding-left: 10px;
    padding-right: 9px;
    cursor: pointer;
    color: rgba(0, 0, 0, 0.72);
}

.producto .container-cantidad .btn-sumar, .producto-presentacion .container-cantidad .btn-sumar {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
}

.producto .container-cantidad input, .producto-presentacion .container-cantidad input {
    width: 38px;
    border: 1px solid rgba(0, 0, 0, 0.22);
    border-left: none;
    border-right: none;
    text-align: center;
    outline: none;
    font-weight: 100;
    box-shadow: none;
}

.supermercado .btn-restar, .supermercado .btn-sumar, .buscador .btn-restar, .buscador .btn-sumar,
.producto.combo .btn-restar, .producto.combo .btn-sumar {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--bg-boton-principal);
    color: white !important;
    font-weight: 900;
    border: none !important;
}

.supermercado .container-cantidad input, .buscador .container-cantidad input {
    font-weight: 700;
    width: 56px;
}

.buscador.grilla .container-cantidad input {
    width: 96px;
}

@media (max-width: 714px) {
    .supermercado .btn-restar, .supermercado .btn-sumar, .buscador .btn-restar, .buscador .btn-sumar {
        width: 90px;
    }

    .buscador .container-cantidad, .supermercado .container-cantidad, .producto.combo .container-cantidad {
        margin-top: 5px;
        width: 100%;
    }

    .buscador .container-cantidad input, .supermercado .container-cantidad input,
    .producto.combo .container-cantidad input {
        width: 100%;
        height: 35px;
    }

    .buscador.grilla .container-cantidad input {
        width: 76px;
        height: inherit;
    }

    .buscador.grilla .btn-restar, .buscador.grilla .btn-sumar {
        width: inherit;
    }

    .buscador.grilla .container-cantidad {
        margin-top: 0;
        width: 40%;
    }
}


.ribbon-wrapper {
    width: 85px;
    height: 88px;
    overflow: hidden;
    position: absolute;
    top: -3px;
    right: -3px;
}

.ribbon {
    font: 11px Montserrat;
    color: #fff;
    text-align: center;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    position: relative;
    padding: 7px 0;
    background-color: var(--bg-etiqueta);
    left: -5px;
    top: 15px;
    width: 120px;
    color: #fff;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.35);
    z-index: 6;
}

.ribbon:before,
.ribbon:after {
    content: "";
    border-top: 3px solid var(--bg-etiqueta);
    border-left: 3px solid transparent;
    border-right: 3px solid transparent;
    position: absolute;
    bottom: -3px;
    filter: brightness(80%);
}

.ribbon:before {
    left: 0;
}

.ribbon:after {
    right: 0;
}