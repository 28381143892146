.quienes-somos{
padding-bottom: 80px;
}

.quienes-somos h2{
    font-family: Montserrat;
    font-weight: 500;
    font-size: x-large;
    padding-top: 44px;
    padding-bottom: 25px;
    text-align: center;
}

.quienes-somos h2 i{
    color: var(--tx-highlight);
}

.quienes-somos p{
    font-family: Montserrat;
    color: #515151;
    font-size: medium;
}

.quienes-somos p.light{
    font-weight: 100;
}
.quienes-somos p.estrellita:after{
    content: url("../../assets/images/estrellita.png");
    position: relative;
    margin-left: 10px;
    font-size: larger;
}
.quienes-somos p.estrellita:before{
    content: url("../../assets/images/estrellita.png");
    position: relative;
    margin-right: 10px;
}

.quienes-somos p.semi-bold{
    font-weight: 200;
}