ul.breadcrum {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
    flex-wrap: wrap;
}

.container-breadcrum {
    display: flex;
    justify-content: space-between;
    padding-left: 3px;
    padding-right: 3px;
    padding-bottom: 15px;
}

@media (max-width: 768px) {
    .container-breadcrum {
        padding-right: 15px;
        padding-left: 15px;
    }
}

ul.breadcrum li:after {
    font-family: "Iconos";
    content: "\e902";
    font-size: 10px;
    margin: 10px;
    color: #575656bd;
}

ul.breadcrum li:last-child:after {
    content: '';
}

ul.breadcrum li a {
    color: #3483fa;
    font-family: Montserrat;
    cursor: pointer;
    font-weight: 100;
}


ul.breadcrum li.active a {
    color: #272727;
    font-family: Montserrat;
    cursor: default;
    text-decoration: none;
}

