.buscador {
    /*padding-top: 25px;*/
    padding-bottom: 55px;
    background-color: #eee;
    display: flex;
}

.buscador .productos {
    width: 100%;
}

.buscador .producto {
    width: 100%;
    max-width: 290px;
    margin: 10px;
    transition: all ease .1s;
}

@media (min-width: 1200px) {
    .container-home.barra-activa .buscador:not(.grilla) .producto {
        max-width: 266px;
        margin: 8px;
    }
}


@media (max-width: 714px) {
    .buscador:not(.grilla) .producto {
        max-width: 178px;
        margin: 3px;
        padding-top: 20px;
    }

    .buscador .productos {
        padding-top: 0px;
    }
}

@media (max-width: 360px) {
    .buscador:not(.grilla) .producto {
        max-width: 174px;
        margin: 2px;
    }
}

.buscador .filtros {
    background-color: #EEEEEE;
    padding: 15px;
    width: 270px;
}

.buscador .datos-filtros h4 {
    font-weight: 600;
    font-size: x-large;
    text-transform: capitalize;
    padding-top: 9px;
}

.buscador .producto .container-botones {
    margin-top: 5px;
}

.buscador h2 {
    font-family: Montserrat;
    font-weight: 500;
    margin: 15px;
    font-size: 30px;
    max-width: 750px;
    text-align: center;
}

@media (max-width: 596px) {
    .buscador h2 {
        font-size: 13px;
    }
}

@media (max-width: 307px) {
    .buscador h2 {
        font-size: 9px;
    }
}

.buscador h2 i {
    color: #ea4e69;
}

.buscador .container-limit {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
    overflow-y: hidden;
}

.filtros {
    /*display: flex;*/
}

.buscador-breadcrum {
    padding: 19px;
    padding-top: 40px;
    padding-bottom: 0;
}

.tag-close {
    white-space: nowrap;
    font-size: 13px;
    padding: 5px 8px;
    margin: 0 .1em;
    background-color: #FFFFFF;
    color: #666;
    width: fit-content;
    margin-top: 5px;
    margin-bottom: 5px;
    display: inline-block;
}

.tag-close a {
    color: #bbb;
    cursor: pointer;
    opacity: 0.6;
    padding-left: 5px;
}

.tag-close a:hover {
    opacity: 1.0
}

.tag-close svg {
    vertical-align: bottom;
    top: 0;
}

.tag-close a {
    margin: 0 0 0 .3em;
}

.tag-close a svg {
    color: #ccc;
    margin-bottom: 2px;
}

.filtros-opciones .ordenar {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.filtros-opciones svg {
    margin-left: 6px;

}

.filtros-opciones .ordenar select {
    margin-right: 10px;
}

.filtros-opciones li, a {
    text-decoration: none;
    color: #6d6d6d;
    list-style: none;
}

.filtros-opciones div {
    padding-top: 10px;
    padding-bottom: 6px;
    max-height: 3010px;
    overflow-y: auto;
}


/*------------ESTILOS GRILLA-------------*/

.buscador.grilla .producto {
    max-width: none;
    width: 100%;
    margin: 0;
    border-radius: 0;
    display: flex;
    flex-wrap: wrap;
}

.buscador.grilla .producto:first-child {
    margin-top: 0;
}

.buscador.grilla .producto:last-child {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
}

.buscador.grilla .producto:hover {
    transform: none;
}

.buscador.grilla .producto .sub-container-botones {
    flex: 1 1 100%;
    display: flex;
    justify-content: flex-end;
    position: relative;
    margin-top: -35px;
}

@media (max-width: 1100px) {
    .container-home.barra-activa .buscador.grilla .producto .sub-container-botones {
        margin-top: 10px;
    }
}

.buscador.grilla .producto .container-botones {
    border: none;
    margin: 0;
    padding: 0;
    padding-bottom: 15px;
    padding-top: 12px;
    min-width: 307px;
}

.buscador.grilla .producto .container-detalle {
    flex: 1 1;
    padding-left: 25px;
    min-height: inherit;
}

.buscador.grilla .producto .container-img {
    min-height: inherit;
    margin-bottom: 3px;
    width: 310px;
}

.buscador.grilla .productos {
    padding-left: 20px;
    padding-right: 20px;
}

.buscador.grilla .producto .nombre-producto p, .buscador.grilla .producto .detalle-producto p {
    font-weight: 100;
    font-size: x-large;
    text-align: left;
    line-height: initial;
}

@media (max-width: 1100px) {
    .container-home.barra-activa .buscador.grilla .producto .nombre-producto p,
    .container-home.barra-activa .buscador.grilla .producto .detalle-producto p {
        font-size: small;
    }
}

.buscador.grilla .producto .detalle-producto p {
    font-size: small;
}

.buscador.grilla .producto .container-botones button {
    margin: 0;
    padding: 0;
    padding-bottom: 10px;
    width: 150px;
    -webkit-align-self: normal;
    align-self: normal;
    padding-top: 10px;
}

.buscador.grilla .producto {
    box-shadow: 0px 0px 4px -1px rgba(0, 0, 0, 0.19);
    padding-left: 25px;
    padding-right: 45px;
}


/*ESTILO GRILLA RESPONSIVE*/
@media (max-width: 930px) {
    .buscador.grilla .producto .nombre-producto p, .buscador.grilla .producto .detalle-producto p {
        font-size: large;
    }

    .buscador.grilla .producto .detalle-producto p {
        font-size: small;
    }

    .buscador.grilla .producto .container-img {
        width: 130px;
    }

    .buscador.grilla .producto .container-botones {
        border: none;
    }

    .buscador.grilla .productos {
        padding-left: 0px;
        padding-right: 0px;
        padding-top: 0px;
    }
}


@media (max-width: 714px) {
    .buscador {
        padding-top: 5px;
    }

    .buscador .filtros {
        display: none;
    }

    .buscador.grilla .producto {
        padding-left: 15px;
        padding-right: 15px;
        margin: 8px;
        margin-bottom: 5px;
        margin-top: 5px;
        border-radius: 4px;
    }

    .buscador.grilla .producto .sub-container-botones {
        margin-top: 10px;
    }

    .buscador.grilla .producto .nombre-producto p, .buscador.grilla .producto .detalle-producto p {
        font-size: large;
        font-weight: 200;
    }

    .buscador.grilla .producto .detalle-producto p {
        font-size: small;
        font-weight: 100;
    }

    .buscador.grilla .producto .container-img {
        width: 96px;
    }

    .buscador.grilla .producto .container-botones {
        border-top: 1px solid #00000038;
        margin-top: 15px;
        padding-top: 10px;
        padding-bottom: 0;
        min-width: inherit;
        width: 100%;
    }

    .buscador.grilla .producto .container-detalle {
        padding-left: 10px;

    }

    .buscador.grilla .producto .container-botones button {
        width: 40%;
    }

    .buscador.grilla .producto .container-botones .button-consultar button {
        width: 100%;
    }
}

.filter-nav {
    background-color: white;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 20px;
    padding-top: 20px;
    display: none;
}

@media (max-width: 714px) {
    .filter-nav {
        display: block;
    }
}

.filtros-atributos.responsive {
    padding-left: 15px;
    padding-right: 15px;
    background-color: white;
    display: none;
}

@media (max-width: 714px) {
    .filtros-atributos.responsive {
        display: block;
    }
}

.filtros-atributos.responsive .tag-close {
    font-size: 13px;
    padding: 9px 17px;
    border-radius: 50px;
    margin: 0 .1em;
    background-color: #f5f5f5;
    color: #646161;
    width: fit-content;
    margin-top: 5px;
    margin-bottom: 5px;
    display: inline-block;
}

.filter-tools {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
}

.tool-container {
    color: #3483fa;
    text-align: center;
    display: flex;
    justify-content: center;
    width: 33%;
}

.tool-icon:before {
    font-family: iconos;
    margin-right: 5px;
    position: relative;
    top: 1px;
}

.tool-container .ordenar:before {
    content: '\e91b';
}

.tool-container:nth-child(2) {
    position: relative;
}

.tool-container:nth-child(2):after {
    content: '';
    display: block;
    border-right: 1px solid #00000045;
    width: 0px;
    height: 17px;
    position: absolute;
    top: 2px;
    right: 0px;
}

.tool-container:nth-child(2):before {
    content: '';
    display: block;
    border-right: 1px solid #00000045;
    width: 0px;
    height: 17px;
    position: absolute;
    top: 2px;
    left: 0px;
}

.tool-container .listado:before {
    content: '\e91d';
}

.tool-container .square:before {
    content: '\e918';
}

.tool-container .filtrado:before {
    content: '\e91a';
}

.tool-container button {
    background: none;
    border: none;
    outline: none;
}

.filtros.responsive {
    display: none;
    padding: 15px;
    padding-bottom: 0px;
}

@media (max-width: 714px) {
    .filtros.responsive {
        display: block;
    }
}

.filtros.responsive h4 {
    font-weight: 600;
    font-size: x-large;
    text-transform: capitalize;
    padding-top: 9px;
}

.buscador:not(.grilla) .producto .container-img {
    align-items: flex-end !important;
    min-height: 90px;
    margin-bottom: 3px;
}