.direcionEntregaRegistro .container-limit {
    max-width: 700px;
}

@media (max-width: 714px) {
    .direcionEntregaRegistro .container-limit {

    }
}

.direcionEntregaRegistro .container-direccionEntregaRegistro-form .campoTexto {
    margin: 0px;
}

.direcionEntregaRegistro .container-direccionEntregaRegistro-form #input-calle {
    width: 60%;
}

.direcionEntregaRegistro .container-direccionEntregaRegistro-form #input-numero {
    width: 30%;
}

.direcionEntregaRegistro .container-direccionEntregaRegistro-form #input-codPostal {
    width: 60%;
}

.direcionEntregaRegistro .container-direccionEntregaRegistro-form #input-provincia {
    width: 50%;
}

.direcionEntregaRegistro .container-direccionEntregaRegistro-form #input-localidad {
    width: 50%;
}

.direcionEntregaRegistro .container-direccionEntregaRegistro-form .fila {
    display: flex;
    justify-content: space-between;
}

.direcionEntregaRegistro .container-botones {
    display: flex;
    justify-content: space-evenly;
    margin-top: 80px;
    margin-bottom: 100px !important;
}

.direcionEntregaRegistro .container-botones button {
    background: none;
    border: none;
    box-shadow: none;
    text-shadow: none;
    background-color: var(--bg-btn-confirmar);
    color: var(--tx-btn-confirmar);
    font-family: Montserrat;
    font-weight: 200;
    padding-top: 8px;
    padding-bottom: 7px;
    border-radius: 48px;
    width: 45%;
}

.direcionEntregaRegistro .container-botones button:focus {
    outline: none;
}

.direcionEntregaRegistro .campoTexto textarea:focus + .campoTexto-label {
    top: -12px;
    cursor: inherit;
    font-size: 14px;
    color: var(--label-campo-texto);
}

.direcionEntregaRegistro .campoTexto textarea + .campoTexto-label.show {
    top: -12px;
    cursor: inherit;
    font-size: 14px;
}