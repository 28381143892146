.productos {
    padding-top: 20px;
}

.productos .producto .container-detalle, .productos-hm .producto .container-detalle {
    min-height: 51px;
}

.productos .container-productos {
    margin-top: 5px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.producto {
    border-radius: 4px;
    /*width: 260px;*/
    padding: 15px;
    padding-top: 45px;
    -webkit-box-shadow: 0px 0px 7px -1px rgba(0, 0, 0, 0.19);
    -moz-box-shadow: 0px 0px 7px -1px rgba(0, 0, 0, 0.19);
    box-shadow: 0px 0px 7px -1px rgba(0, 0, 0, 0.19);
    /*transition: all .2s ease-in-out;*/
    position: relative;
    height: 100%;
    background-color: white;
}

.productos .swiper-button-disabled {
    display: none;
}

@media (max-width: 714px) {
    .producto {
        margin-left: 7px;
        margin-right: 7px;
    }
}

.producto:hover {
    transform: scale(1.01);
}

.row-categoria h4 {
    padding-left: 18px;
    font-size: 24px;
    font-family: Montserrat;
    font-weight: 200;
    margin-bottom: 0px;
    display: inline;
}

.row-categoria a b {
    margin-top: 15px;
    margin-left: 13px;
}

@media (max-width: 714px) {
    .row-categoria h4 {
        padding-left: 8px;
        margin-bottom: 5px;
        display: block;
    }
}

.row-categoria {
    margin-bottom: 21px;
    margin-top: 21px;

}

@media (max-width: 714px) {
    .row-categoria {
        margin-top: 0px;
        margin-bottom: 0px;
    }
}

.productos-hm {
    display: flex;
    width: 100%;
    flex-wrap: nowrap;
    overflow-y: hidden;
    overflow-x: scroll;
    flex-direction: row;
    padding-bottom: 25px;
}

.productos-hm .producto {
    width: 220px;
    margin: 5px;
    padding-bottom: 5px;
}


.etiqueta-promo {
    position: absolute;
    top: 12px;
    color: var(--tx-etiqueta);
    font-family: Montserrat;
    font-weight: 200;
    font-size: 10px;
    background-color: var(--bg-etiqueta);
    display: -webkit-inline-box;
    padding-left: 8px;
    padding-right: 8px;
    padding-bottom: 5px;
    padding-top: 5px;
    border-radius: 3px;
    /*margin-bottom: 34px;*/
    margin-top: 3px;
    line-height: 11px;
    max-width: 197px;
    z-index: 5;
}

@media (max-width: 714px) {
    .etiqueta-promo {
        top: 8px;
        font-size: 8px;
        padding-left: 5px;
        padding-right: 5px;
        padding-bottom: 5px;
        padding-top: 5px;
        max-width: 141px;
    }

    .buscador:not(.grilla) .producto .etiqueta-promo {
        font-size: 6px;
        max-width: 102px;
        line-height: normal;
    }

}

.producto .container-img {
    margin-bottom: 10px;
    min-height: 164px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (max-width: 714px) {
    .producto .container-img {
        min-height: 100px;
        margin-bottom: 3px;
    }
}

.producto .container-img img {
    width: 100%;
    max-width: 180px;
    object-fit: scale-down;
    height: 170px;
    margin: 0 auto;
    display: block;
    z-index: 1;
}

@media (max-width: 714px) {
    .producto .container-img img {
        max-width: 90px;
        height: 90px;
    }
}

.producto .container-img img.finalizada {
    position: absolute;
    left: 23%;
    z-index: 3;
}

.producto .precio-oferta {
    position: relative;
    z-index: 0;
}

.producto .div-circular {
    position: absolute;
    top: -186px;
    left: 56px;
    background-color: #8f227a;
    border-radius: 100%;
    z-index: -1;
    min-height: 70px;
    min-width: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.producto .precio-oferta p {
    color: white;
    font-family: "Montserrat";
    font-size: 19px;
    padding: 0px;
    margin: 0;
}

.producto span.num-decimal {
    vertical-align: 6px;
    padding-left: 1px;
    font-size: 11px;
}

.producto .nombre-producto p, .producto .detalle-producto p {
    text-align: center;
    font-family: "Montserrat";
    font-weight: 200;
    font-size: smaller;
    margin-bottom: 0;
    white-space: normal;
    line-height: normal;
}

@media (max-width: 714px) {
    .producto .nombre-producto p {
        font-size: smaller;
        line-height: 12px;
        margin: 3px 0;
    }

    .producto .detalle-producto p {
        font-size: xx-small !important;
        line-height: normal;
    }
}

.producto .nombre-producto p a {
    color: black;
}

.producto .detalle-producto p {
    font-weight: 100;
    font-size: small;
}

.producto-carrito .producto .detalle-producto p {
    font-size: x-small;
}

.producto .precios-vigentes {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    /* margin-top: 15px; */
    /* margin-bottom: 15px; */
    /* justify-content: space-between; */
    min-height: 42px;
}

.producto .ver-precio {
    margin-right: 10px;
}

.producto .cantidades-vigentes {
    display: flex;
    justify-content: space-between;
}

.producto .precios-vigentes p, .producto .precios-vigentes b, .producto .cantidades-vigentes {
    margin-bottom: 0px;
    font-family: Montserrat;
    font-weight: 200;
    font-size: x-small;
    padding-left: 15px;
    padding-right: 5px;
}

.producto .precios-vigentes b {
    font-size: large;
    padding-left: 5px;
}

@media (max-width: 714px) {
    .producto .precios-vigentes b {
        font-size: medium;
    }
}

.producto .precios-vigentes div {
    /*width: 33.33333333333333%;*/
    /*text-align: center;*/
    position: relative;
    text-align: end;
}

.producto .precios-vigentes .costo-final {
    display: flex;
}

.producto .precios-vigentes .costo-final p {
    margin: 0;
    padding: 0;
    color: green;
    white-space: nowrap;
    align-self: flex-end;
    font-size: smaller;
    font-family: Montserrat;
    font-weight: 100;
    letter-spacing: -1px;
    margin-bottom: 2px;
}

@media (max-width: 714px) {
    .producto .precios-vigentes .costo-final p {
        font-size: x-small;
    }
}

.producto .precios-vigentes .costo-medio {
    border-left: 1px solid #f37032;
    border-right: 1px solid #f37032;
}


.producto .container-info p {
    text-align: center;
    margin-bottom: 0;
    font-family: Montserrat;
    font-weight: 100;
    font-size: smaller;
}

.producto .barra-progreso {
    width: 100%;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 15px;
    padding-right: 15px;
}

.producto .barra-progreso .guia-barra {
    width: 100%;
    border-radius: 10px;
    height: 9px;
    background-color: #e5e5e5;
}

.producto .barra-progreso .barra {
    background-color: #ea4e69;
    width: 7%;
    max-width: 100%;
    height: 100%;
    border-radius: 10px;;
}

.producto .container-botones {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
    border-top: 1px solid #00000026;
    padding-top: 11px;
    min-height: 57px;
}

@media (max-width: 714px) {
    .producto .container-botones {
        flex-wrap: wrap;
        padding-top: 0px;
    }
}

.producto .container-botones button {
    background: none;
    border: none;
    box-shadow: none;
    text-shadow: none;
    background-color: var(--bg-boton-principal);
    color: var(--tx-boton-principal);
    font-family: Montserrat;
    font-weight: 200;
    padding-top: 4px;
    padding-bottom: 7px;
    border-radius: 48px;
    width: 110px;
    font-size: 11px;
}

@media (max-width: 714px) {
    .producto .container-botones button {
        font-size: inherit;
        padding-top: 8px;
        width: 100%;
        margin-top: 5px;
    }
}

.producto .container-botones button:focus {
    outline: none;
}

.producto .container-botones .encargar {
    background-color: var(--bg-boton-principal);
    white-space: nowrap;
}

.producto .container-botones .encargar:disabled {
    background-color: var(--bg-boton-principal);
    filter: brightness(.6);
    cursor: not-allowed;
}

.producto .container-botones .encargar svg {
    max-width: 15px;
    fill: var(--tx-boton-principal);
    top: 2px;
    position: relative;
    stroke: var(--tx-boton-principal);
    stroke-width: 18px;
}

.producto .tachado:after {
    content: url("../images/tachado.png");
    position: absolute;
    background-color: rgba(255, 255, 255, 0.64);
    top: 5px;
    width: 100%;
    right: 0;
    height: 100%;
}

.producto .disabled:after {
    content: '';
    position: absolute;
    background-color: rgba(255, 255, 255, 0.64);
    top: 5px;
    width: 100%;
    right: 0;
    height: 100%;
}


@media (max-width: 714px) {
    .supermercado {
        padding-bottom: 12px !important;
    }
}

.sub-container-botones {
    width: 100%;
}

.paginas {
    display: flex;
    justify-content: center;
    padding-bottom: 50px;
    flex-wrap: wrap;
}

.numero-pagina {
    width: 35px;
    height: 35px;
    color: #333;
    font-weight: 400;
    align-items: center;
    display: flex;
    vertical-align: center;
    justify-content: center;
    margin-left: 5px;
    margin-right: 5px;
    cursor: pointer;

}

.numero-pagina.active {
    color: rgba(0, 0, 0, .8);
    background-color: rgba(0, 0, 0, .04);
    font-weight: 600;
}