.codigoPromocional{
    width: 55%;
    margin-top: 60px;
}

.codigoPromocional .containerCodigoPromocional{
    display: flex;
    justify-content: flex-start;
    align-items: baseline;
}

.codigoPromocional .containerCodigoPromocional div{
    flex-grow: 6;
}

@media(max-width: 714px){
    .codigoPromocional{
        width: 100%;
    }
}

.codigoPromocional button {
    background: none;
    border: none;
    box-shadow: none;
    text-shadow: none;
    background-color: white;
    border: 1px solid  var(--bg-btn-confirmar);
    color:  var(--bg-btn-confirmar);
    font-family: Montserrat;
    font-weight: 200;
    padding-top: 7px;
    padding-bottom: 6px;
    border-radius: 4px;
    width: 100px;
    margin-left: 6px;
}

.codigoPromocional button:focus {
    outline: none;
}