.barra-informacion {
    display: flex;
    margin-top: 50px;
    margin-bottom: 15px;
    justify-content: space-between;
    border-radius: 4px;
    box-shadow: 4px 3px 10px rgba(0, 0, 0, 0.20);
    padding: 17px 25px;
}

@media (max-width: 714px) {
    .barra-informacion {
        flex-wrap: wrap;
        justify-content: space-between;
    }
}

.barra-informacion .img-feature {
    border-radius: 100%;
    border: 1px solid rgba(0, 0, 0, 0.12);
    margin-right: 15px;
}

.barra-informacion .img-feature img {
    max-width: 76px;
    object-fit: contain;
    padding: 10px;
}

.barra-informacion .info-feature {
    display: flex;
    align-items: center;
}

@media (max-width: 714px) {
    .barra-informacion .info-feature {
        margin-top: 15px;
        margin-bottom: 15px;
    }
}

.txt-cont-feature {
    margin-right: 15px;
}

.txt-cont-feature p {
    margin-bottom: 0;
}

.txt-cont-feature p:first-child {
    font-family: Montserrat;
    font-weight: 200;
    font-size: large;
}

.txt-cont-feature p:last-child {
    font-family: "Montserrat";
    font-weight: 100;
    font-size: 12px;
    color: #3483fa;
}