.footer{
    background-color: var(--bg-footer);
    width: 100%;
    z-index: 999999;
    padding-bottom: 10px;
}
.container-items-footer{
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 50px;
    margin-bottom: 30px;
}

.redes-sociales a{
    margin-right: 5px;
    margin-left: 5px;
}
.redes-sociales a:last-child{
    margin-right: 0px;
}

.container-items-footer ul{
    display: flex;
    list-style: none;
    padding-left: 10px;
    padding-right: 5px;
    margin-bottom: 8px;
}

@media (max-width: 714px) {
    .container-items-footer ul{
        flex-direction: column;
    }
}
.container-items-footer li{
    margin-right: 10px;
    margin-left: 10px;
    font-family: Montserrat;
    font-weight: 200;
    font-size: 12px;
    margin-bottom: 8px;
    margin-top: 8px;
}
.container-items-footer li a{
    color: #fafafa61;
}
.container-items-footer li a:hover{
    color: #fafafa;
    text-decoration: none;
}
.footer .redes-sociales{
    text-align: center;
}
.footer .redes-sociales a{
    color: #fafafa61;
}

.footer .redes-sociales a:hover{
    color: #fafafa;
}

.footer .container-copyright p{
    color: #fafafa61;
    text-align: center;
    font-family: Montserrat;
    font-weight: 100;
    font-size: 10px;
    margin-top: 23px;
    padding-bottom: 22px;
}

.firma{
    padding-left: 20px;
    display: flex;
    align-items: center;
}
.exadec{
    background-image: url("../images/exadec.png");
    background-repeat: no-repeat;
    background-size: 65%;
    width: 100px;
    height: 18px;
    position: relative;
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    -ms-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
}

.desa{
    color: grey;
}
.desa:hover{
    text-decoration: none;
    color: white;
}
.desa:hover .exadec{
    background-image: url("../images/exadec2.png");
}

.data-fiscal{
    max-width: 30px;
}