.container-popover {
    position: relative;
    width: 0px;
}

.pop-over {
    display: none;
    position: absolute;
    left: -90px;
    top: 7px;
    background-color: white;
    border: 1px solid #00000042;
    border-radius: 4px;
    box-shadow: 2px -1px 5px #6679;
    z-index: 9;
}

.pop-over::after {
    content: '';
    display: block;
    position: absolute;
    top: -7px;
    right: 48%;
    transform: rotate(45deg);
    z-index: 0;
    width: 14px;
    height: 14px;
    background-color: #f7f7f7;
    box-shadow: -2px -2px 2px #6666776b;
    /*border-left: 10px solid transparent;*/
    /*border-right: 10px solid transparent;*/
    /*border-top: 10px solid #f7f7f7;*/
}


.pop-over h6 {
    background-color: #f7f7f7;
    color: #667;
    margin: 0;
    padding: 15px;
    border-radius: 3px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    font-family: Montserrat;
    font-weight: 600;
    font-size: 13px;
}

.pop-over.show {
    display: block;
}

.pop-over .body-modal {
    padding: 5px;
}

.mis-pedidos .pop-over table {
    margin-bottom: 0;
}

.body-modal table tr th {
    vertical-align: middle;
    height: 44px;
}


label.label-estado {
    border-radius: 4px;
    padding: 2px 7px;
    padding-top: 4px;
    box-shadow: 2px 2px 4px -1px #00000040;
    font-weight: 600;
    font-size: 13px;
    cursor: pointer;
}

label.label-estado.default {
    background-color: #ff7f50c7;
    color: white;

}


label.label-estado.danger {
    background-color: rgba(255, 25, 28, 0.78);
    color: white;
}


label.label-estado.warning {
    background-color: rgba(100, 255, 104, 0.78);
    color: white;
}


