.carrousel-combo .swiper-wrapper {
    padding-top: 0 !important;
}

.carrousel-combo .swiper-button-prev:after, .carrousel-combo .swiper-button-next:after {
    font-size: 20px !important;
}

.carrousel-combo .swiper-button-next, .carrousel-combo .swiper-button-prev {
    color: var(--slider-nav-color);
    display: none;
}

.supermercado .producto:hover .carrousel-combo .swiper-button-next,
.supermercado .producto:hover .carrousel-combo .swiper-button-prev,
.buscador .producto:hover .swiper-button-prev, .buscador .producto:hover .swiper-button-next {
    display: flex;
}
