.cuerpo-configuracion {
    background-color: white;
    max-width: 1040px;
    border-radius: 4px;
    box-shadow: 0px 0px 7px -1px rgba(0, 0, 0, 0.19);
    margin-top: 15px;
    margin-bottom: 15px;
    padding: 25px;
}

.mis-datos .formulario-container {
    display: flex;
    flex-direction: column;
    max-width: 500px;
}

.mis-datos .formulario-container input {
    margin-top:5px;
    margin-bottom: 25px;
    padding-left: 5px;
    padding-top: 5px;
    padding-bottom: 5px;
}