.alerta-update{
    position: fixed;
    max-width: 250px;
    width: 100%;
    bottom: 15px;
    left: 50%;
    margin-top: -100px;
    margin-left: -120px;
    background-color: black;
    z-index: 99999;
    color: white;
    display: flex;
    padding: 15px 10px;
    border-radius: 5px;
    box-shadow: 2px 2px 6px #00000085;
    justify-content: center;
}

.alerta-update p{
    margin-bottom: 0;
    margin-right: 5px;
}