.producto-presentacion {
    padding-top: 40px;
    min-height: calc(100vh - 280px);
    background-color: rgb(238, 238, 238);
    padding-bottom: 80px;
}

.producto-presentacion .producto {
    display: flex;
    flex-direction: row;
    width: 100%;
    /*justify-content: space-around;*/
    margin-left: 0;
    border-radius: 0;
    background-color: transparent;
    border-right: 1px solid #0000002b;
    margin-bottom: 0;
    padding-bottom: 10px;
    padding-top: 0px;
    padding-left: 0px;
    min-height: 500px;
}

@media (max-width: 768px) {
    .producto-presentacion .producto {
        border: none;
        border-bottom: 1px solid #0000002b;
        padding-left: 15px;
    }
}

.producto-presentacion .container-img {
    max-width: 450px;
    width: 100%;
    align-self: center;
    margin: 15px auto;
}

.producto-presentacion .producto .container-img img {
    max-height: 350px;
    max-width: 350px;
    height: inherit;
}

.producto-presentacion .container-precio-detalle {
    border-top: 1px solid #0000002b;
    border-right: 1px solid #0000002b;
    padding: 32px;
}

.producto-presentacion .producto .div-circular {
    top: -295px;
    left: 16px;
}


.producto-presentacion .container-productos {
    display: flex;
    flex-wrap: wrap;
}

.container-producto-presentacion {
    border-radius: 4px;
    box-shadow: 0px 0px 7px -1px rgba(0, 0, 0, 0.19);
    background-color: white;
}

.producto-presentacion .detalle-producto p,
.producto-presentacion .nombre-producto p,
.producto-presentacion .estado-actual p,
.producto-presentacion .fecha-promo p {
    text-align: left;
}


.producto-presentacion .barra-progreso {
    padding-left: 0;
    padding-right: 0;
}

.producto-presentacion .container-botones {
    width: 100%;
    margin-top: 25px;
    margin-bottom: 40px;
}

.producto-presentacion .detalles-features ul {
    margin-top: 26px;
    margin-bottom: 25px;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
}

.producto-presentacion .detalles-features ul p {
    font-family: Montserrat;
    font-size: 15px;
    font-weight: 200;
}

.producto-presentacion .container-titulo p {
    font-family: Montserrat;
    font-size: 30px;
    font-weight: 200;
    text-align: left;
    margin-bottom: 0;
    line-height: normal;
}

.producto-presentacion .container-titulo .detalle-producto p {
    font-size: 14px;
    font-weight: 100;
    margin: 0;
    color: var(--tx-subt-producto);
}

@media (max-width: 714px ) {
    .producto-presentacion {
        padding-top: 55px;
    }

    .producto-presentacion .container-titulo .detalle-producto p {
        margin-bottom: 50px;
    }

    .producto-presentacion .container-titulo p {
        font-size: 20px;
        padding-right: 15px;
        padding-left: 15px;
    }

}

@media (max-width: 450px) {
    .producto-presentacion .detalles-features ul {
        -webkit-column-count: 1;
        -moz-column-count: 1;
        column-count: 1;
    }
}

.producto-presentacion .container-img img.finalizada {
    left: 9%;
}

.producto-presentacion .producto:hover {
    transform: none;
}


.producto-presentacion .wrapper-principal {
    max-width: 65%;
    width: 100%;
    height: 100%;
}

.producto-presentacion .wrapper-secundario {
    max-width: 35%;
    width: 100%;
    padding: 32px;
    padding-top: 35px;
}


@media (max-width: 1200px) {
    .container-home.barra-activa .producto-presentacion .galeria-fotos {
        display: none;
    }

    .container-home.barra-activa .producto-presentacion .wrapper-principal {
        max-width: 100%;
    }

    .container-home.barra-activa .producto-presentacion .wrapper-secundario {
        max-width: 100%;
    }
}

@media (max-width: 768px) {
    .producto-presentacion .wrapper-principal {
        max-width: 100%;
    }

    .producto-presentacion .wrapper-secundario {
        max-width: 100%;
    }
}

@media (max-width: 850px) {
    .producto-presentacion .galeria-fotos {
        display: none;
    }
}

.producto-presentacion .galeria-fotos img {
    width: 100px;
    height: auto;
    max-height: 100px;
    min-height: 100px;
    overflow: hidden;
    object-fit: scale-down;
    object-position: center center;
    padding-bottom: 5px;
    padding-top: 5px;
    padding-left: 15px;
    padding-right: 15px;
    border-top: 1px solid #0000002b;
    border-left: 1px solid #0000002b;
    border-right: 1px solid #0000002b;

}

.producto-presentacion .galeria-fotos img:first-child {
    border-top: none;
    border-top-left-radius: 4px;
}

.producto-presentacion .galeria-fotos img:last-child {
    border-bottom: 1px solid #0000002b;
}

.producto-presentacion .react-photo-gallery--gallery div:first-child {
    flex-direction: column !important;
}

.producto-presentacion .costo-final {
    display: flex;
    align-items: center;
}

.producto-presentacion .costo-final b {
    font-family: Montserrat;
    font-weight: 300;
    font-size: 47px;
}

.producto-presentacion .costo-final b span {
    font-size: 26px;
    vertical-align: super;
    font-weight: 300;
    position: relative;
    top: 5px;
    left: 2px;
}


.producto-presentacion .costo-final p {
    margin-left: 15px;
    color: #00a650;
    font-family: Montserrat;
    font-weight: 200;
    font-size: 18px;
}

.producto-presentacion .costo-inicial b {
    color: #999;
    font-weight: 400;
    font-family: Montserrat;
    font-size: large;
    line-height: 0;
}

.producto-presentacion .mas-info {
    position: relative;
}

.producto-presentacion .cantidad-precio {
    margin-top: 15px;
}

.producto-presentacion .container-cantidad {
    display: flex;
    flex-wrap: nowrap;
}

.producto-presentacion .etiqueta-promo {
    position: relative;
}

.producto-presentacion .container-cantidad input {
    width: 48px;
    font-weight: inherit;
}

.producto-presentacion .encargar {
    display: block;
    margin-top: 20px;
    height: 35px;
    background: none;
    width: 100%;
    border: none;
    text-shadow: none;
    background-color: var(--bg-boton-principal);
    color: var(--tx-btn-confirmar);
    font-family: Montserrat;
    font-weight: 100;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 15px;
    border-radius: 50px;
    outline: none;
    white-space: nowrap;
    padding-right: 32px;
}

.producto-presentacion .encargar svg {
    max-width: 15px;
    fill: var(--tx-btn-confirmar);
    top: 2px;
    position: relative;
    stroke: var(--tx-btn-confirmar);
    stroke-width: 18px;
    margin-left: 5px;
}

.producto-presentacion .detalles-features li {
    width: 50%;
    margin-bottom: 15px;

}

.producto-presentacion .detalles-features b {
    display: block;
    font-weight: 300;
    color: #999;
}

.producto-presentacion .detalles-features span {
    color: black;
    font-size: 21px;
    line-height: 19px;
}

.producto-presentacion .container-detalle {
    border-bottom: 1px solid #0000002b;
}

.descripcion-producto p {
    margin-top: 25px;
    font-size: 18px;
    color: #665;
    font-family: Montserrat;
    font-weight: 100;
}

.productos-relacionados {
    margin-top: 55px;
}

@media (max-width: 768px) {
    .productos-relacionados h3 {
        padding-left: 15px;
        padding-right: 15px;
    }
}

.productos-relacionados .producto {
    max-width: 280px;
    flex-direction: column;
    padding: 15px;
    min-height: inherit;
    border-radius: 4px;
}

@media (max-width: 714px) {
    .productos-relacionados .producto {
        margin: 0 auto;
    }
}

.productos-relacionados .etiqueta-promo {
    font-size: 10px;
    font-weight: 200;
    color: white;
    margin: 0;
}

.productos-relacionados .producto .container-img img {
    height: 180px;
    max-width: 180px;
    margin: 0 auto;
}

.productos-relacionados .container-detalle {
    border: none;
}

.productos-relacionados .nombre-producto p {
    text-align: center;
    margin: 0;
}

.productos-relacionados .container-botones {
    display: flex;
    justify-content: flex-end;
    margin-top: 0px;
    border-top: 1px solid #00000026;
    padding-top: 11px;
    min-height: 57px;
    margin-bottom: 0;
}

.productos-relacionados .producto .container-botones button {
    background: none;
    border: none;
    box-shadow: none;
    text-shadow: none;
    background-color: var(--bg-boton-principal);
    color: var(--tx-boton-principal);
    font-family: Montserrat;
    font-weight: 200;
    padding: 0;
    border-radius: 48px;
    width: 120px;
    margin: 0;
}

@media (max-width: 714px) {
    .productos-relacionados .container-botones {
        margin-top: 0px;
        padding-top: 0px;
    }

    .productos-relacionados .producto .container-botones button {
        width: 100%;
        margin-top: 5px;
    }
}

.productos-relacionados .container-img {
    margin: 5px auto;
}

.productos-relacionados .producto .precios-vigentes p,
.productos-relacionados .producto .precios-vigentes b,
.productos-relacionados .producto .cantidades-vigentes {
    margin-bottom: 0px;
    font-family: Montserrat;
    font-weight: 200;
    font-size: large;
    padding-left: 15px;
    padding-right: 15px;
}

.productos-relacionados .detalle-producto a {
    text-transform: inherit;
    color: #337ab7 !important;
}

.productos-relacionados .producto.combo:hover .swiper-button-next,
.productos-relacionados .producto.combo:hover .swiper-button-prev {
    display: flex;
    opacity: 1;
}

@media (max-width: 714px ) {
    .descripcion-producto ul {
        padding-left: 10px;
    }
}

.descripcion-producto ul.lista-promos li {
    display: flex;
    align-items: center;
    padding: 0;
    margin-top: 15px;
    margin-bottom: 15px;
}

.descripcion-producto ul.lista-promos img {
    width: 60px;
    height: 60px;
    object-fit: scale-down;
}

.descripcion-producto ul.lista-promos li p {
    margin: 0;
    font-size: small;
    padding-left: 15px;
}

.producto-presentacion .promocion {
    margin-top: 50px;
}

.producto-presentacion a {
    color: var(--tx-menu-desplegable) !important;
    font-family: Montserrat;
    text-transform: uppercase;
    font-size: 12px;
}

.producto-presentacion a.registrate {
    border: 1px solid var(--tx-menu-desplegable) !important;
    padding: 8px 10px;
    border-radius: 4px;
}

.producto-presentacion .swiper-slide {
    align-self: center;
}

.galeria-fotos {
    max-height: 501px;
    overflow-y: auto;
}

.galeria-fotos {
    max-height: 501px;
    overflow-y: auto;
    -ms-overflow-style: none;
}

.galeria-fotos::-webkit-scrollbar {
    display: none;
}

@media (max-width: 769px) {
    .button-consultar {
        width: 100%;
        height: 35px;
    }

    .grilla .button-consultar {
        width: 40%;
        height: 46px;
    }
}