.slider-principal {
    max-width: 100vw;
}

.slider-principal .item {
    /*height: 550px;*/
    width: 100%;
    background: no-repeat center center;
    /*background-size: contain;*/
}

.slider-principal .item img {
    width: 100%;
    /*height: 100%;*/
    /*object-fit: cover;*/
}

/*@media (max-width: 800px) {*/
/*    .slider-principal .item {*/
/*        height: 410px;*/
/*    }*/
/*}*/

.slider-principal .banner-txt {
    padding: 20px;
    max-width: 790px;
    font-family: "Montserrat";
    color: white;
    font-weight: 600;
    text-shadow: 1px 1px 10px black;
}


.slider-principal .banner-txt h1 {
    color: white;
    text-align: center;
    font-size: 45px;
}

.slider-principal .banner-txt ul {
    font-weight: 400;
    font-size: 18px;
}


@media (max-width: 714px) {
    .slider-principal .banner-txt h1 {
        font-size: 25px;
    }

    .slider-principal .banner-txt h2 {
        font-size: 18px;
    }

}