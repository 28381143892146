.datosEntrega {
    padding-right: 25px;
    padding-left: 25px;
}

.subtitulo-checkout {
    font-size: 25px;
    text-align: left;
    margin-top: 30px;
    font-weight: 400;
}

.datosEntrega .container-botones {
    display: flex;
    justify-content: space-evenly;
    margin-top: 80px;
    margin-bottom: 100px !important;
}

.datosEntrega .container-botones button {
    background: none;
    border: none;
    box-shadow: none;
    text-shadow: none;
    background-color: var(--bg-btn-confirmar);
    color: var(--tx-btn-confirmar);
    font-family: Montserrat;
    font-weight: 200;
    padding-top: 8px;
    padding-bottom: 7px;
    border-radius: 48px;
    width: 45%;
}

.datosEntrega .container-botones button:focus {
    outline: none;
}
