.compra-exitosa {
    min-height: calc(100vh - 326px);
    padding-top: 82px;
    padding-bottom: 50px;
}

.compra-exitosa .imagen-circular img{
    max-width: 120px;
    max-height: 120px;
    margin: 0 auto;
    display: block;
}

.compra-exitosa h2{
    text-align: center;
    color: var(--tx-compra-conf);
    font-family: Montserrat;
    font-weight: 200;
    margin-top: 30px;
    margin-bottom: 30px;
}

.compra-exitosa p{
    text-align: center;
    font-family: Montserrat;
    font-weight: 100;
    max-width: 350px;
    margin: 10px auto;
}

/*.compra-exitosa .container-botones button{*/
    /*background: none;*/
    /*border:none;*/
    /*box-shadow: none;*/
    /*text-shadow: none;*/
    /*background-color: #8f227a;*/
    /*color: white;*/
    /*font-family: Montserrat;*/
    /*font-weight: 200;*/
    /*padding-top: 8px;*/
    /*padding-bottom: 7px;*/
    /*border-radius: 48px;*/
    /*margin: 35px auto;*/
    /*display: block;*/
    /*padding-left: 23px;*/
    /*padding-right: 25px;*/
/*}*/

.compra-exitosa .container-botones{
    display: flex;
    justify-content: space-evenly;
    margin: 30px auto;
    max-width: 600px;

}

.compra-exitosa .container-botones button{
    background: none;
    border:none;
    box-shadow: none;
    text-shadow: none;
    background-color: var(--bg-compra-conf-pagar);
    color: var(--tx-compra-conf-voler);;
    font-family: Montserrat;
    font-weight: 200;
    padding-top: 8px;
    padding-bottom: 7px;
    border-radius: 48px;
    width: 45%;
}
.compra-exitosa .container-botones button:focus{
    outline: none;
}
.compra-exitosa .container-botones .encargar{
    background-color: var(--btn-compra-conf-voler);
    white-space: nowrap;
}
.compra-exitosa .container-botones .encargar svg{
    max-width:15px;
    fill: var(--tx-compra-conf-voler);
    top: 0px;
    position: relative;
    stroke: var(--tx-compra-conf-voler);
    stroke-width: 18px;
}