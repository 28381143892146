.barra-categorias .swiper-slide {
    width: 150px;
    max-height: 50px;
    align-self: center;
}

.barra-categorias .swiper-container.swiper-container-initialized.swiper-container-horizontal.swiper-container-free-mode {
    align-self: center;
    height: 100%;
}

.item-categ {
    height: 100%;
    display: contents;
}

.barra-categorias .carrousel-buttons {
    max-width: 1269px;
    position: relative;
    margin: 0 auto;
    top: -44px;
    display: flex !important;
}

.buscador-section .barra-categorias .carrousel-buttons {
    top: -40px;
}

.barra-categorias .swiper-button-prev.swiper-button-disabled,
.barra-categorias .swiper-button-next.swiper-button-disabled {
    opacity: 0 !important;
}

.barra-categorias .carrousel-buttons .swiper-button-prev,
.barra-categorias .swiper-container-rtl .swiper-button-next,
.barra-categorias .carrousel-buttons .swiper-button-next,
.barra-categorias .carrousel-buttons .swiper-container-rtl .swiper-button-prev {
    border: none;
    background-color: transparent;
    box-shadow: none;
    width: calc(44px / 44 * 27);
}

.barra-categorias .carrousel-buttons .swiper-button-next:after,
.barra-categorias .carrousel-buttons .swiper-container-rtl .swiper-button-prev:after {
    font-size: 21px;
    color: var(--border-bot-cat);
}

.barra-categorias .carrousel-buttons .swiper-button-prev:after,
.barra-categorias .carrousel-buttons .swiper-container-rtl .swiper-button-next:after {
    font-size: 21px;
    color: var(--border-bot-cat);
}