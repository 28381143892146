/*.campoTexto {*/
/*    position: relative;*/
/*    margin-bottom: 30px;*/
/*}*/
/*@media (max-width: 714px) {*/
/*    .campoTexto {*/
/*        margin-bottom: 20px;*/
/*    }*/
/*}*/

/*.campoTexto div {*/
/*    border-bottom: solid black 1px;*/
/*    padding-bottom: 4px;*/
/*}*/

/*.campoTexto input {*/
/*    border: none;*/
/*    width: calc(100% - 26px) !important;*/
/*    font-size: 17px;*/
/*    font-family: Montserrat;*/
/*    font-weight: 100;*/
/*    margin-left: 10px;*/
/*    transition: border-color 0.2s;*/
/*    padding-left: 5px;*/
/*    background-color: transparent;*/
/*    color: black !important;*/
/*}*/

/*@media (max-width: 714px) {*/
/*    .campoTexto input{*/
/*        font-size: 13px !important;*/
/*    }*/
/*}*/

/*.campoTexto label {*/
/*    position: absolute;*/

/*    left: 31px;*/
/*    font-family: Montserrat;*/
/*    font-weight: 100;*/
/*    pointer-events: none;*/


/*    font-size: 12px;*/
/*    top: -14px;*/
/*    color: var(--label-campo-texto) !important;*/
/*}*/

/*.campoTexto input:placeholder-shown ~ .campoTexto-label {*/
/*    cursor: text;*/
/*    font-style: italic;*/
/*    color: var(--label-campo-texto) !important;*/
/*    top: 2px;*/
/*    font-size: 16px;*/
/*}*/

/*@media (max-width: 714px) {*/
/*    .campoTexto input:placeholder-shown ~ .campoTexto-label {*/
/*        font-size: 13px !important;*/
/*    }*/
/*}*/

/*.campoTexto input:focus {*/
/*    outline-offset: 0px;*/
/*    outline: none;*/
/*}*/

/*.campoTexto-linea {*/
/*    border-color: var(--label-campo-texto) !important;*/
/*}*/

/*.campoTexto input:focus ~ .campoTexto-linea {*/
/*    border-bottom: 2px solid var(--label-campo-texto) !important;*/
/*    -webkit-animation: aparecer 0.5s ease-in 0s both;*/
/*    -moz-animation: aparecer 0.5s ease-in 0s both;*/
/*    -ms-animation: aparecer 0.5s ease-in 0s both;*/
/*    -o-animation: aparecer 0.5s ease-in 0s both;*/
/*    animation: aparecer 0.5s ease-in 0s both;*/
/*}*/

/*@keyframes aparecer {*/
/*    0% {*/
/*        -webkit-transform: scaleX(0);*/
/*    }*/
/*    100% {*/
/*        -webkit-transform: scaleX(100%);*/
/*    }*/
/*}*/

/*@-webkit-keyframes aparecer {*/
/*    0% {*/
/*        -webkit-transform: scaleX(0);*/
/*    }*/
/*    100% {*/
/*        -webkit-transform: scaleX(100%);*/
/*    }*/
/*}*/

/*@-moz-keyframes aparecer {*/
/*    0% {*/
/*        -webkit-transform: scaleX(0);*/
/*    }*/
/*    100% {*/
/*        -webkit-transform: scaleX(100%);*/
/*    }*/
/*}*/

/*@-ms-keyframes aparecer {*/
/*    0% {*/
/*        -webkit-transform: scaleX(0);*/
/*    }*/
/*    100% {*/
/*        -webkit-transform: scaleX(100%);*/
/*    }*/
/*}*/

/*@-o-keyframes aparecer {*/
/*    0% {*/
/*        -webkit-transform: scaleX(0);*/
/*    }*/
/*    100% {*/
/*        -webkit-transform: scaleX(100%);*/
/*    }*/
/*}*/


/*.campoTexto input::-webkit-input-placeholder {*/
/*    font-style: italic;*/
/*}*/

/*.campoTexto input::placeholder {*/
/*    color: transparent;*/
/*}*/

/*.campoTexto-input::placeholder {*/
/*    color: transparent;*/
/*}*/

/*!*.campoTexto-input:placeholder-shown ~ .campoTexto-label {*!*/
/*!*font-size: 16px;*!*/
/*!*cursor: text;*!*/
/*!*top: 0px;*!*/
/*!*position: absolute;*!*/
/*!*left: 20px;*!*/

/*!*}*!*/

/*.campoTexto-input:focus ~ .campoTexto-label {*/
/*    position: absolute;*/
/*    top: -14px !important;*/
/*    display: block;*/
/*    transition: 0.2s !important;*/
/*    font-size: 12px !important;*/
/*    color: var(--label-campo-texto) !important;*/
/*    font-style: normal !important;*/
/*}*/

/*.campoTexto-input:focus {*/
/*    padding-bottom: 6px;*/
/*}*/

/*.campoTexto svg {*/
/*    max-height: 16px;*/
/*    max-width: 16px;*/
/*}*/

.campoTexto {
    margin: 15px 0;
    width: 100%;
}

.campoTexto label {
    position: relative;
    display: block;
    width: 100%;
    min-height: 45px;
}

.campoTexto .campoTexto-label {
    position: absolute;
    display: block;
    top: 20px;
    z-index: 2;
    font-size: 16px;
    transition: all 200ms ease-in-out;
    width: 100%;
    cursor: text;
    pointer-events: none;
    font-family: Montserrat;
    font-weight: 100;
    text-align: left;
}

.campoTexto input,
.campoTexto textarea {
    position: relative;
    top: 15px;
    z-index: 1;
    width: 100%;
    font-size: 16px;
    border: 0;
    border-bottom: 1px solid grey;
    transition: border-color 200ms ease-in-out;
    outline: none;
    padding: 0;
    margin: 0;
    font-family: Montserrat;
    font-weight: 100;
}

.campoTexto textarea {
    min-height: 30px;
    padding: 8px 0;
}

.campoTexto input {
    height: 30px;
}

.campoTexto input:focus,
    /*.campoTexto input:valid,*/
.campoTexto textarea:focus
    /*.campoTexto textarea:valid */
{
    border-bottom: 2px solid var(--label-campo-texto);
}

.campoTexto input:focus + .campoTexto-label,
    /*.campoTexto input:valid + .campoTexto-label,*/
.campoTexto textarea:focus + .campoTexto-label
    /*.campoTexto textarea:valid + .campoTexto-label */
{
    top: 0;
    cursor: inherit;
    font-size: 14px;
    color: var(--label-campo-texto);
}

.campoTexto .campoTexto-label.show,
.campoTexto .campoTexto-label.show {
    top: 0;
    cursor: inherit;
    font-size: 14px;
}