.banner-lanzamiento {
    background-color: var(--bg-howto-principal);
    -webkit-background-size: cover;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 175px;
    position: relative;
}

.banner-lanzamiento .img-fondo{
    position: absolute;
    opacity: 0.25;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 0;
    object-fit: cover;
}

.banner-lanzamiento .titulo-promo {
    font-family: Montserrat;
    color: white;
    max-width: 230px;
    align-self: center;
    padding-left: 15px;

    -webkit-animation: ease-in-out infinite;
    -webkit-animation-name: titulo-promo-animacion;
    -webkit-animation-duration: 25s;
    position: relative;
}

@media(max-width: 714px) {
    .banner-lanzamiento .titulo-promo{
        -webkit-animation: none;
    }
}
.banner-lanzamiento .titulo-promo h3 {
    text-align: right;
    text-transform: uppercase;
}

.banner-lanzamiento .titulo-promo h3 i {
    color: var(--tx-highlight2);
    text-transform: uppercase;
}

.banner-lanzamiento .container-camioncito {
    width: 100%;
    align-self: center;
    margin-left: 17px;
    padding-top: 5px;
}

.banner-lanzamiento .container-camioncito img {
    width: 100%;
    height: auto;
    max-width: 90px;
    -webkit-animation: ease-in-out infinite;
    -webkit-animation-name: camion-animacion;
    -webkit-animation-duration: 25s;
    position: relative;
    filter:hue-rotate(var(--hue-deegre));
}

@media (max-width: 714px) {
    .banner-lanzamiento .container-camioncito img {
        -webkit-animation: none;
    }
}

@-webkit-keyframes camion-animacion {
    0% {
        left: 0;
    }
    50% {
        left: calc(100% - 170px);
    }
    100% {
        left: calc(100% - 170px);
    }
}

@-webkit-keyframes titulo-promo-animacion {
    0% {
        left: 0;
    }
    50% {
        left: calc(100% - 400px);
    }

    100% {
        left: calc(100% - 400px);
    }
}