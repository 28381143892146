.spinner-div{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
}

.spinner {
    animation: rotator 1.4s linear infinite;
}

@keyframes rotator {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(270deg);
    }
}

.path {
    stroke-dasharray: 187;
    stroke-dashoffset: 0;
    transform-origin: center;
    animation: dash 1.4s ease-in-out infinite,
    colors 4.4s ease-in-out infinite;
}

@keyframes colors {
    0% {
        stroke: #F1683F;
    }
    25% {
        stroke: #E94F69;
    }
    50% {
        stroke: #A81E7E;
    }
    75% {
        stroke: #FFBF00;
    }
    100% {
        stroke: #FF700F;
    }
}

@keyframes dash {
    0% {
        stroke-dashoffset: 187;
    }
    50% {
        stroke-dashoffset: 46;
        transform: rotate(135deg);
    }
    100% {
        stroke-dashoffset: 187;
        transform: rotate(450deg);
    }
}
