@font-face {
    font-family: "Montserrat";
    font-weight: 100;
    src: local("?"),
    url("../../assets/font/Montserrat-Light.woff") format("woff"),
    url("../../assets/font/Montserrat-Light.ttf") format("truetype"),
    url("../../assets/font/Montserrat-Light.otf") format("otf");
    font-display: swap;
}

@font-face {
    font-family: "Montserrat";
    font-weight: 200;
    src: local("?"),
    url("../../assets/font/Montserrat-Regular.woff") format("woff"),
    url("../../assets/font/Montserrat-Regular.ttf") format("truetype"),
    url("../../assets/font/Montserrat-Regular.otf") format("otf");
    font-display: swap;
}

@font-face {
    font-family: "Montserrat";
    font-weight: 300;
    src: local("?"),
    url("../../assets/font/Montserrat-SemiBold.woff") format("woff"),
    url("../../assets/font/Montserrat-SemiBold.ttf") format("truetype"),
    url("../../assets/font/Montserrat-SemiBold.otf") format("otf");
    font-display: swap;
}

@font-face {
    font-family: "Montserrat";
    font-weight: 400;
    src: local("?"),
    url("../../assets/font/Montserrat-Bold.woff") format("woff"),
    url("../../assets/font/Montserrat-Bold.ttf") format("truetype"),
    url("../../assets/font/Montserrat-Bold.otf") format("otf");
    font-display: swap;
}

@font-face {
    font-family: "Montserrat";
    font-weight: 500;
    src: local("?"),
    url("../../assets/font/Montserrat-ExtraBold.woff") format("woff"),
    url("../../assets/font/Montserrat-ExtraBold.ttf") format("truetype"),
    url("../../assets/font/Montserrat-ExtraBold.otf") format("otf");
    font-display: swap;
}

@font-face {
    font-family: "Iconos";
    src: local("?"),
    url("../../assets/font/iconos.woff2") format("woff2"),
    url("../../assets/font/iconos.ttf") format("truetype");
    font-display: swap;
}