.como-comprar{
    background: var(--bg-howto-principal);
    background: -moz-linear-gradient(-45deg, var(--bg-howto-principal) 0%, var(--bg-howto-secundari) 69%, var(--bg-howto-terciario) 100%);
    background: -webkit-gradient(left top, right bottom, color-stop(0%, var(--bg-howto-principal)), color-stop(69%, var(--bg-howto-secundari)), color-stop(100%, var(--bg-howto-terciario)));
    background: -webkit-linear-gradient(-45deg, var(--bg-howto-principal) 0%, var(--bg-howto-secundari) 69%, var(--bg-howto-terciario) 100%);
    background: -o-linear-gradient(-45deg, var(--bg-howto-principal) 0%, var(--bg-howto-secundari) 69%, var(--bg-howto-terciario) 100%);
    background: -ms-linear-gradient(-45deg, var(--bg-howto-principal) 0%, var(--bg-howto-secundari) 69%, var(--bg-howto-terciario) 100%);
    background: linear-gradient(135deg, var(--bg-howto-principal) 0%, var(--bg-howto-secundari) 69%, var(--bg-howto-terciario) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#8f2279', endColorstr='#b93671', GradientType=1 );
    /*height: 600px;*/
    margin-top: 0px;
    padding-bottom: 80px;
}

.titulo-seccion.titulo-home {
    color: var(--tx-howto-color);
    margin-top: 0px;
    font-weight: bold;
    padding-top: 59px;
}

.titulo-seccion.titulo-home i {
    color: var(--tx-highlight);
    padding-right: 4px;
}

.como-comprar .container-features {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}

.como-comprar .feature {
    padding: 15px;
}

.como-comprar .feature img {
    margin: 10px auto;
    display: block;
    max-width: 175px;
    filter: drop-shadow(2px 4px 6px rgba(0, 0, 1, 0.88)) hue-rotate(var(--hue-deegre));
}

.como-comprar .feature h6 {
    color: var(--tx-howto-color);
    font-family: Montserrat;
    font-weight: bold;
    font-size: large;
    text-align: center;
    padding-top: 10px;
}

.como-comprar .feature p {
    color: var(--tx-howto-color);
    max-width: 250px;
    text-align: center;
    font-family: "Montserrat";
    font-weight: 200;
}

.como-comprar .feature p.feature-info {
    font-weight: 100;
}

.como-comprar .feature p.feature-info i {
    color: var(--tx-highlight);
}