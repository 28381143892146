.contacto {
    padding-bottom: 125px;


    background: var(--bg-contact-principal);
    background: -moz-linear-gradient(-45deg, var(--bg-contact-principal) 0%, var(--bg-contact-secundari) 80%, var(--bg-contact-terciario) 100%);
    background: -webkit-gradient(left top, right bottom, color-stop(0%, var(--bg-contact-principal)), color-stop(80%, var(--bg-contact-terciario)), color-stop(100%, var(--bg-contact-terciario)));
    background: -webkit-linear-gradient(-45deg, var(--bg-contact-principal) 0%, var(--bg-contact-secundari) 80%, var(--bg-contact-terciario) 100%);
    background: -o-linear-gradient(-45deg, var(--bg-contact-principal) 0%, var(--bg-contact-secundari) 80%, var(--bg-contact-terciario) 100%);
    background: -ms-linear-gradient(-45deg, var(--bg-contact-principal) 0%, var(--bg-contact-secundari) 80%, var(--bg-contact-terciario) 100%);
    background: linear-gradient(135deg, var(--bg-contact-principal) 0%, var(--bg-contact-secundari) 80%, var(--bg-contact-terciario) 100%);

}

.contacto p {
    color: var(--tx-contact-text);
    font-family: Montserrat;
    font-weight: 200;
    padding-left: 15px;
}

.contacto .container-inputs {
    padding: 15px;
}

.contacto .container-inputs input:first-child {
    margin-top: 0;
}

.contacto .container-inputs input {
    display: block;
    width: 100%;
    margin-top: 15px;
    height: 38px;
    border: 1px solid var(--tx-contact-text);;
    background: none;
    font-family: Montserrat;
    font-weight: 100;
    color: var(--tx-contact-text);
    padding-left: 12px;
    outline: none;
}

.contacto .container-inputs input::placeholder {
    font-family: Montserrat;
    font-weight: 100;
    color: var(--tx-contact-text);
}

.contacto .container-inputs textarea{
    width: 100%;
    height: 100%;
    background: none;
    border: 1px solid var(--tx-contact-text);
    text-shadow: none;
    font-family: Montserrat;
    font-weight: 100;
    color: var(--tx-contact-text);
    padding-left: 12px;
    outline: none;
}

.contacto .container-inputs textarea::placeholder{
    font-family: Montserrat;
    font-weight: 100;
    color: var(--tx-contact-text);
}


@media (max-width: 714px) {
    .contacto .container-inputs textarea{
        height: 200px;
    }
}

.contacto .btn-enviar{
    background: none;
    color: var(--tx-btn-contact);
    border: none;
    background-color: var(--bg-btn-contact);
    border-radius: 36px;
    font-weight: 100;
    font-family: Montserrat;
    padding: 10px 45px;
    font-size: small;
    float: right;
    margin-right: 15px;
    margin-top: 15px;
    outline: none;


}

@keyframes blink {
    0% {
        opacity: .2;
    }
    20% {
        opacity: 1;
    }
    100% {
        opacity: .2;
    }
}

.saving span {
    animation-name: blink;
    animation-duration: 1.4s;
    animation-iteration-count: infinite;
    animation-fill-mode: both;
}

.saving span:nth-child(2) {
    animation-delay: .2s;
}

.saving span:nth-child(3) {
    animation-delay: .4s;
}