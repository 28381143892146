.supermercado {
    margin-top: 0px;
    padding-bottom: 20px;
}

.supermercado .mujeres {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-left: 15px;
    padding-right: 15px;
}

.supermercado .producto {
    margin: 10px;
    transition: all ease .1s;
}

@media (max-width: 714px) {
    .supermercado .producto {
        margin: 5px;
    }
}

.supermercado .producto .container-botones {
    margin-top: 5px;
}

@media (max-width: 714px) {
    .supermercado .producto .container-botones {
        margin-top: 0px;
        padding-top: 3px;
    }
}

.supermercado .mujeres img {
    max-width: 250px;
    flex: 1 1 auto;
    margin-right: 15px;
}

.supermercado h2 {
    font-family: Montserrat;
    font-weight: 500;
    margin: 15px;
    font-size: 30px;
    max-width: 750px;
    text-align: center;
}

@media (max-width: 596px) {
    .supermercado .mujeres img {
        max-width: 100px;
    }

    .supermercado h2 {
        font-size: 13px;
    }
}

@media (max-width: 307px) {
    .supermercado .mujeres img {
        max-width: 70px;
    }

    .supermercado h2 {
        font-size: 9px;
    }
}

.supermercado h2 i {
    color: var(--tx-highlight);
}

.supermercado .container-limit {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
    overflow-y: hidden;
}