.carrousel-buttons {
    max-width: 1225px;
    position: relative;
    margin: 0 auto;
    top: -220px;
    display: none;
}

.carrousel-buttons:hover {
    display: block;
}

.productos .container-limit:hover ~ .carrousel-buttons {
    display: block;
}

@media (max-width: 714px) {
    .carrousel-buttons {
        display: none !important;
    }
}

.carrousel-buttons .swiper-button-next,
.carrousel-buttons .swiper-container-rtl .swiper-button-prev {
    right: -4px;
}

.carrousel-buttons .swiper-button-prev,
.carrousel-buttons .swiper-container-rtl .swiper-button-next {
    left: -4px;
}

.carrousel-buttons .swiper-button-prev:after,
.carrousel-buttons .swiper-container-rtl .swiper-button-next:after {
    font-size: 24px;
    color: var(--btn-color-principal);
}

.carrousel-buttons .swiper-button-next:after,
.carrousel-buttons .swiper-container-rtl .swiper-button-prev:after {
    font-size: 24px;
    color: var(--btn-color-principal);
}

.carrousel-buttons .swiper-button-prev, .swiper-container-rtl .swiper-button-next, .carrousel-buttons .swiper-button-next,
.carrousel-buttons .swiper-container-rtl .swiper-button-prev {
    width: 54px;
    height: 54px;
    background-color: #fff;
    border-radius: 100%;
    border-width: 0;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .19);
    cursor: pointer;
    outline: 0;
    z-index: 2;
}

.productos .swiper-wrapper {
    padding-top: 8px;
}

.swiper-container-horizontal > .swiper-scrollbar {
    width: 100px !important;
    top: 0px !important;
    right: 8px !important;
    z-index: 50 !important;
    height: 3px !important;
    left: inherit !important;
    opacity: 0;
}

.productos .container-limit:hover .swiper-container-horizontal > .swiper-scrollbar {
    opacity: 1;
}

.swiper-container-horizontal > .swiper-scrollbar.show {
    opacity: 1;
}


.productos .carrousel-destacados .swiper-slide {
    width: 300px !important;
}

.productos .carrousel-combo .swiper-slide {
    width: inherit !important;
}
