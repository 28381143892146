.login-sign-up {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.44);
    width: 100vw;
    height: 100vh;
    z-index: 99;
    top: 0;
    display: flex;
    justify-content: center;
    /*overflow-y: scroll;*/
}

.login-sign-up .container-modal {
    background-color: white;
    width: 60%;
    align-self: center;
    box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.45);
    padding: 0;
    overflow-y: auto;
}

.login-sign-up .container-doble {
    height: 70vh;
    overflow-y: auto;
}

@media (max-width: 1100px) {
    .login-sign-up .container-modal {
        width: 80%;
    }
}

@media (max-width: 714px) {
    .login-sign-up .container-modal {
        width: 100%;
    }

    .login-sign-up .container-doble {
        height: 100vh;
    }
}


.login-sign-up .container-marca {
    background: var(--bg-login-principal);
    background: -moz-linear-gradient(top, var(--bg-login-principal) 0%, var(--bg-login-secundari) 82%, var(--bg-login-terciario) 100%);
    background: -webkit-gradient(left top, left bottom, color-stop(0%, var(--bg-login-principal)), color-stop(82%, var(--bg-login-secundari)), color-stop(100%, var(--bg-login-terciario)));
    background: -webkit-linear-gradient(top, var(--bg-login-principal) 0%, var(--bg-login-secundari) 82%, var(--bg-login-terciario) 100%);
    background: -o-linear-gradient(top, var(--bg-login-principal) 0%, var(--bg-login-secundari) 82%, var(--bg-login-terciario) 100%);
    background: -ms-linear-gradient(top, var(--bg-login-principal) 0%, var(--bg-login-secundari) 82%, var(--bg-login-terciario) 100%);
    background: linear-gradient(to bottom, var(--bg-login-principal) 0%, var(--bg-login-secundari) 82%, var(--bg-login-terciario) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f36f32', endColorstr='#ea4f69', GradientType=0);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 100px 15px;
}

.login-sign-up .container-marca .logo {
    fill: white;
    max-width: 200px;
    max-height: 200px;
    width: 100%;
    object-fit: contain;
}

@media (max-width: 714px) {
    .login-sign-up .container-marca .logo {
        max-height: 50px;
    }

    .login-sign-up .container-marca {
        padding-top: 30px;
        padding-bottom: 30px;
        position: relative;
    }
}

.login-sign-up .container-yaregistrado {
    text-align: center;
}

.login-sign-up .container-yaregistrado p {
    color: white;
    margin-top: 30px;
    margin-bottom: 30px;
    font-family: Montserrat;
    font-weight: 100;
    max-width: 150px;
}

@media (max-width: 714px) {
    .login-sign-up .container-yaregistrado p {
        margin-top: 15px;
        margin-bottom: 15px;
        max-width: 250px;
    }
}

.login-sign-up .container-yaregistrado button {
    background: none;
    border: 1px solid white;
    color: white;
    font-family: Montserrat;
    font-weight: 100;
    border-radius: 100px;
    padding: 4px 15px;
    outline: none;
}

.login-sign-up .container-yaregistrado button:hover, .login-sign-up .container-yaregistrado button:active {
    outline: none;
}

.login-sign-up .container-datos {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    padding: 35px 0;
    position: relative;
}


@media (max-width: 714px) {
    .login-sign-up .container-datos {
        padding: 15px;
        padding-bottom: 150px;
    }
}

.login-sign-up .container-datos h2 {
    font-family: Montserrat;
    font-weight: 500;
    font-size: x-large;
    padding-top: 10px;
    margin-bottom: 20px;
}

.login-sign-up .container-datos h2 i {
    color: var(--tx-highlight);
}

.login-sign-up .container-redes svg {
    border: 1px solid black;
    border-radius: 100%;
    padding: 8px;
    width: 30px;
    height: 30px;
    margin: 5px;
    cursor: pointer;
}

.login-sign-up .container-datos p {
    color: #0a0f15;
    font-family: Montserrat;
    font-weight: 100;
    font-size: 13px;
    /*margin-top: 20px;*/
    margin-bottom: 4px;
}

.login-sign-up .container-datos form {
    display: flex;
    flex-direction: column;
    max-width: 270px;
    width: 100%;
    align-self: center;
}

.login-sign-up .container-datos form input {
    margin: 6px;
    border-radius: 100px;
    box-shadow: none;
    border: 1px solid rgba(0, 0, 0, 0.5);
    padding: 7px 15px;
    font-family: Montserrat;
    font-weight: 200;
    color: black;
    outline: none;
}

.login-sign-up .container-datos form button {
    background: none;
    margin: 20px 0px 0px 0px;
    align-self: center;
    font-family: Montserrat;
    font-weight: 100;
    background-color: var(--bg-btn-confirmar);
    color: white;
    border-radius: 100px;
    box-shadow: none;
    border: none;
    padding: 8px 24px;
    outline: none;
}

.login-sign-up .cruz-cerrar {
    position: absolute;
    top: 2px;
    right: 15px;
    font-size: 23px;
    color: #0000008a;
    cursor: pointer;
}

@media (max-width: 714px) {
    .login-sign-up .cruz-cerrar {
        position: fixed;
    }
}

.login-sign-up .olvidePass {
    padding: 15px;
}

.login-sign-up .errores p {
    padding: 2px 30px;
    margin: 0;
    color: #ea4e69;
    fontWeight: "300";
}

.login-sign-up .containerDireccionUsuario div{
    flex:1 1 50%;
}

.login-sign-up .containerDireccionUsuario > :nth-child(1){
    order: 2;
    flex:1 1 50%;
}

.login-sign-up .containerDireccionUsuario > :nth-child(2){
    order: 1;
    flex:1 1 50%;
}

.login-sign-up .botonera{
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.login-sign-up .diasEntrega b{
    margin: 4px;
}

.login-sign-up .diasEntrega .active{
    background-color: var(--bg-login-principal);
    border-radius: 100%;
    padding: 2px 4px;
}

.login-sign-up .mensajeExito button {
    background:  var(--bg-login-principal);
    border: 1px solid  var(--bg-login-principal);
    color: white;
    font-family: Montserrat;
    font-weight: 100;
    border-radius: 100px;
    padding: 4px 15px;
    outline: none;
    margin-top: 20px;
}

.login-sign-up .mensajeExito p {
    color:  black;
    font-family: Montserrat;
    font-weight: 200;
    padding: 4px 15px;
}