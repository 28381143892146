.msjError{
    z-index: 9999999;
    position: fixed;
    top: 0;
    left: 0;
    right:0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(62, 63, 63, 0.7);
}

.msjError .ventanaModal{
    z-index: 99999999;
    position: fixed;
    top: 30%;
    left: 0;
    right:0;
    width: 90%;
    max-width: 500px;
    margin: auto;
    background-color: white;
    padding: 20px;
    padding-top: 40px;
    overflow-y: scroll;
}

.msjError .ventanaModal p::first-letter{
    text-transform: capitalize;
    font-weight: bold;
    color: #8f227a;
}

.msjError .container-botones{
    display: flex;
    justify-content: space-evenly;
    margin-top: 42px;
    margin-bottom: 20px;
}

.msjError .container-botones div{
    display: flex;
    justify-content: space-evenly;
}

.msjError .container-botones button{
    background: none;
    border:none;
    box-shadow: none;
    text-shadow: none;
    background-color: #8f227a;
    color: white;
    font-family: Montserrat;
    font-weight: 200;
    border-radius: 48px;
    white-space: nowrap;
    box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.43);
    margin-left: 5px;
    padding: 8px 10px;
}
.msjError .container-botones button:focus{
    outline: none;
}