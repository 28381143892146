.producto-buscado.producto-carrito {
    cursor: pointer;
}

.producto-buscado.producto-carrito .producto {
    margin: 5px 0px;
}

.producto-buscado.producto-carrito .producto .container-img {
    min-width: 70px;
    padding-top: 3px;
    padding-bottom: 3px;
}

.producto-buscado.producto-carrito .producto .container-img img {
    max-height: 60px;
    margin: 0px auto !important;
}

.producto-buscado.producto-carrito .container-precio p {
    font-weight: 700;
}

@media (max-width: 714px) {
    .producto-buscado.producto-carrito .container-precio p {
        font-size: smaller;
    }
}

.producto-buscado.producto-carrito .producto .container-img {
    min-height: unset !important;
}

.producto-buscado.producto-carrito .cantidad-precio {
    padding: 0;
}

.producto-buscado.producto-carrito .container-cantidad input {
    padding: 0;
    height: 25px;
}
